.placeholder {
  text-align: center;
  color: $gray-dark;
  font-size: 16px;
  margin: 0 auto;
  width: 400px;
  max-width: 100%;

  @include mq('phone') {
    width: auto;
    max-width: 280px;
  }

  .icon {
    margin-bottom: 20px;
  }

  &--card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: $placeholder-card-width;
    min-height: 170px;
    border: 2px dashed $gray-light;
    border-radius: 5px;
    padding: 25px 50px;
  }
}

.placeholder__text {
  margin-top: 0;
  max-width: 100%; // fixes not-breakable text in IE11
}

.placeholder__title {
  margin-top: 0;
  font-size: 18px;
  color: $gray-darker;
}

.placeholder__footer {
  color: #999;
  font-size: 12px;
  margin-top: 30px;
}

.pointing-arrow {
  background-image: $pointing-arrow;
  background-position: 25px 0;
  background-repeat: no-repeat;
  // 125px is 25px background left offset + 100px right offset
  background-size: calc(100% - 125px) auto;
}
