.dashboard {
  padding: 40px;
  margin-top: 30px;
  position: relative;
  background-color: $color-app-bg;

  h2 {
    margin-top: 0;
    margin-bottom: 40px;
    text-align: center;
  }

  .btn-request {
    top: 40px;
    right: 40px;
    position: absolute;
  }
}

.dashboard__module {
  padding: 40px;
  margin-top: 15px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: $dashboard-border-radius;
  min-height: 50px;
}

.signet {
  font-size: 30px;
  text-align: center;
  color: $brand-primary;
}

.pill {
  padding: 5px 10px;
  display: inline-block;
  background-color: #fff;
  text-transform: uppercase;
  border: 1px solid #ebebeb;
  border-radius: 4px;

  &-sm {
    font-size: 12px;
    padding: 2px 7px;
  }
}

.date-range {
  display: inline-block;

  .pill {
    min-width: 105px;
  }
}

.badge { // TODO: Extend pill
  padding: 5px 10px;
  background-color: $gray;
  text-transform: uppercase;
  border: 1px solid transparent;
  color: #fff;

  &--sm {
    font-size: 11px;
    padding: 3px 10px 4px;
  }

  &--primary {
    background-color: $brand-primary;
  }

  &--warning {
    background-color: $brand-warning;
  }
}

.pill-group {
  .pill {
    border-radius: 4px;
    border-right-width: 0;
    @include border-right-radius(0);
  }

  .badge {
    vertical-align: baseline;
    line-height: $line-height-base;
    border-radius: 4px;
    border-left-width: 0;
    @include border-left-radius(0);
  }

  &--sm {
    font-size: 12px;
  }
}

.date-range__separator {
  margin: 0 25px;
  color: $gray-light;
}

.icon-circle {
  font-size: 18px;
  margin-right: 6px;
  @include circle(30px);
  display: inline-block;
  background-color: $gray;
  color: #fff;

  &--vacation {
    background-color: #41beee;
  }

  &--health {
    background-color: #f21942;
  }
}
