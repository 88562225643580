.link-default {
  &,
  &:focus,
  &:active {
    color: $gray-darker;
  }

  &:hover {
    color: $gray-darker;
    text-decoration: underline;
  }
}

.link-gray-dark {
  &,
  &:focus,
  &:active {
    color: $gray-dark;
  }

  &:hover {
    color: $gray-dark;
    text-decoration: underline;
  }
}

.text-default {
  color: $gray-darker !important;
  fill: $gray-darker !important;
}

.text-danger {
  color: $brand-danger !important;
  fill: $brand-danger !important;
}

.text-success {
  color: $brand-success !important;
  fill: $brand-success !important;
}

.text-gray {
  color: $gray !important;
  fill: $gray !important;
}

.text-gray-light {
  color: $gray-light !important;
  fill: $gray-light !important;
}

.text-gray-lighter {
  color: $gray-lighter !important;
  fill: $gray-lighter !important;
}

.text-gray-dark {
  color: $gray-dark !important;
  fill: $gray-dark !important;
}

.text-primary {
  color: $brand-primary !important;
  fill: $brand-primary !important;
}

.text-blue {
  color: $brand-secondary !important;
  fill: $brand-secondary !important;
}

.hover-danger:hover {
  color: $brand-danger !important;

  > svg {
    fill: $brand-danger !important;
  }
}

.hover-primary:hover {
  color: $brand-primary !important;

  > svg {
    fill: $brand-primary !important;
  }
}

.hover-secondary:hover {
  color: $brand-secondary !important;

  > svg {
    fill: $brand-secondary !important;
  }
}

.hover-success:hover {
  color: $brand-success !important;

  > svg {
    fill: $brand-success !important;
  }
}

.hover-gray-dark:hover {
  color: $gray-dark !important;

  > svg {
    fill: $gray-dark !important;
  }
}

.hover-gray-darker:hover {
  color: $gray-darker !important;

  > svg {
    fill: $gray-darker !important;
  }
}

.hover-white:hover {
  color: #fff !important;

  > svg {
    fill: #fff !important;
  }
}

.bg-success {
  background-color: $brand-success !important;
  color: #fff;

  > svg {
    fill: #fff;
  }
}

.bg-warning {
  background-color: $brand-warning !important;
  color: #fff;

  > svg {
    fill: #fff;
  }
}

.bg-beta {
  background-color: $brand-beta !important;
  color: #fff;

  > svg {
    fill: #fff;
  }
}

.bg-table-foot {
  background-color: $table-foot-bg !important;
}

.list-item-highlight {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
    background-color: $brand-primary;
  }
}
