.border {
  border: $default-border;
}

.border-radius {
  border-radius: $default-border-radius;
}

.border-top {
  border-top: $default-border;
}

.border-bottom {
  border-bottom: $default-border;
}

.shadow-lg {
  box-shadow: $shadow-lg !important;
}

.p-relative {
  position: relative;
}
