.people-list-table__wrapper {
  border-radius: 3px;
  background-color: #fff;
  border: $default-border;

  &:empty {
    display: none;
  }
}

.people-list-table {
  color: $gray-dark;

  .employee {
    font-size: 16px;
    font-weight: normal;
    display: flex;
    align-items: center;

    &,
    a {
      color: $text-color;
    }
  }
}

.heading-labels {
  width: 100%;
  margin: 5px 0;
  padding: 10px 0 10px 0;
  border: $default-border;
  border-radius: $dashboard-border-radius;
  background-color: $table-head-bg;
}

.people-list-table {
  table-layout: fixed;

  &__name {
    width: 343px;

    @include mq('tablet') {
      width: 267px;
    }
  }

  &__position { width: 260px; }
  &__team { width: 260px; }
  &__location { width: 160px; }

  .skeleton--line {
    width: 150px;
  }

  .skeleton--circle {
    margin-right: 20px;
  }

  &__position {
    .skeleton--line {
      width: 200px;
    }
  }

  &__location {
    .skeleton--line {
      width: 100px;
    }
  }
}

.people-list-table__label {
  font-size: 12px;
  line-height: 1.1;

  &--name {
    width: 372px;
    padding-left: 95px;

    @include mq('tablet') {
      width: 296px;
    }
  }

  &--position { width: 260px; }
  &--team { width: 260px; }
  &--location { width: 160px; }
}
