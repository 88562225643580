.panel {
  overflow: hidden;
  border: $default-border;
  background-color: #fff;
  border-radius: $default-border-radius;
}

.panel--highlighted {
  background-color: $color-app-bg;
}

.panel--placeholder {
  height: 130px;
  margin-bottom: 0;
  color: $gray-dark;
  font-size: 16px;
  padding: 20px;
  text-align: center;
}

.panel__header {
  border-bottom: $default-border;
}

.panel__footer {
  border-top: $default-border;
}

.note {
  background-color: #f7f7f7;
  padding: 11px 15px 15px;
  border-radius: $default-border-radius;
}

.puzzle {
  min-height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 20px;
  text-align: center;

  & + & {
    border-left: $default-border;
  }
}

.puzzle__title {
  font-size: 16px;
}

.puzzle__subtitle {
  font-size: 10px;
  color: $gray-dark;
}

.puzzle__icon {
  $size: 16px;

  position: absolute;
  width: $size;
  height: $size;
  overflow: hidden;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  fill: $gray;
  color: $gray;
  background-color: #fff;
  border: $default-border;
  border-radius: $size;

  .icon {
    width: $size - 8;
    height: $size - 8;
  }
}
