%request {
  margin: 5px 0;
  padding: 10px 40px;
  position: relative;
}

.request {
  @extend %request;
  background-color: #fff;
  border-radius: $dashboard-border-radius;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include mq('*-desktop') {
    padding: 10px 20px 15px;
  }
}

.request__labels {
  font-size: 10px;
  text-transform: uppercase;
}

.request__labels--head {
  @extend %request;
  background-color: darken($color-app-bg, 8%);

  @include mq('*-desktop') {
    display: none;
  }
}

.request__labels--rounded {
  border-radius: $dashboard-border-radius;
}

.request__label {
  font-size: 10px;
  display: inline-block;
  text-transform: uppercase;
}

.request__labels--caption {
  padding: 5px 0;
  margin: 20px 0 5px;
  border-top: $request-border;
  flex: 0 0 100%;
  order: 3;

  .request__label {
    color: $gray;
  }
}

.request--highlighted {
  margin-bottom: 20px;

  &:after {
    width: 100%;
    border-bottom: 1px dashed $gray-light;
    position: absolute;
    bottom: -10px;
    left: 0;
    content: "";
  }

  .employee {
    color: $brand-primary;
  }

  .request__col--preview {
    .icon {
      color: $brand-primary;
    }
  }
}

$labels: 'name' 'date' 'days' 'type';
$widths: 250px 350px 100px 173px;

@each $label in $labels {
  $i: index($labels, $label);
  $width: nth($widths, $i);

  .request__col {
    &--#{$label} {
      flex: 0 0 $width;
    }
  }

  .request__label {
    &--#{$label} {
      width: $width;
    }
  }
}

$widths: 500px 330px 100px 100px; // TODO: Change last width to 1
$orders:  0 4 5 6 1;

@each $label in $labels {
  $i: index($labels, $label);
  $width: nth($widths, $i);
  $order: nth($orders, $i);

  @include mq('*-desktop') {
    .request__col {
      &--#{$label} {
        flex: 0 0 $width;
        order: $order;
      }
    }

    .request__label {
      &--#{$label} {
        width: $width;
      }
    }
  }
}

@include mq('*-desktop') {
  .request__label--name {
    display: none;
  }

  .request__col--preview {
    flex: 1;
    order: 1;
    text-align: right;
  }
}

$labels: 'name' 'date' 'days' 'type' 'preview';
$widths: 100% 100% 50% 50% 1;

@each $label in $labels {
  $i: index($labels, $label);
  $width: nth($widths, $i);
  $order: nth($orders, $i);

  @include mq('phone') {
    .request__col {
      &--#{$label} {
        flex: 0 0 $width;
        order: 1;
      }
    }

    .request__label {
      &--#{$label} {
        width: $width;
      }
    }
  }
}

@include mq('phone') {
  .request__col--name {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: $request-border;
  }

  .request__col--preview {
   padding-top: 10px;
   text-align: center;
   margin: 10px 20px 0;
   border-top: $request-border;
  }
}

.request__name {
  font-size: 16px;
}
