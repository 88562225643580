.workdays__weekday-label {
  font-size: 10px;
}

.workdays {
  justify-content: space-between;

  @include mq('small') {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.workdays__day {
  width: 50px;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
}

.daily--today {
  position: relative;
  overflow: hidden;
  border-radius: $default-border-radius;

  &:before {
    content: '';
    width: 5px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $brand-primary;
  }
}

.date-box {
  display: flex;
  flex-direction: column;
  width: 30px;
  color: $gray-dark;
  text-align: center;

  &__number {
    font-size: 18px;
    font-weight: 500;
  }

  &__weekday {
    font-size: 10px;
    text-transform: uppercase;
  }
}

.date-box--sm {
  .date-box__number {
    font-size: 14px;
  }

  .date-box__weekday {
    font-size: 8px;
  }
}

.date-box--lg {
  .date-box__number {
    font-size: 22px;
    font-weight: bold;
    color: $gray-darker;
  }
}

.time-box {
  color: $gray-darker;
  width: 115px;
}

.time-kind-row + .time-kind-row {
  margin-top: 10px;
}

.timesheet-entry {
  &__wrapper {
    color: $gray-dark;

    &:hover {
      color: $gray-darker;
      text-decoration: none;
    }
  }

  &__duration {
    flex-shrink: 0;
    align-self: flex-start;
    line-height: 40px;
  }

  &__icon-wrapper {
    align-self: flex-start;
    min-height: 40px;
  }

  &__icon {
    fill: $gray;

    &--edit {
      fill: $brand-secondary;
      display: none;
    }
  }

  &:nth-of-type(odd) {
    background-color: $table-head-bg;
  }

  &--interactive:hover {
    background-color: mix($brand-secondary, #fff, 10%);

    .timesheet-entry__link {
      text-decoration: none;
      color: $text-color;
    }

    .time-box {
      color: $text-color;
    }

    .timesheet-entry__icon {
      display: none;

      &--edit {
        display: inline-block;
      }
    }
  }
}
