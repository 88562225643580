.layout {
  @include mq('desktop') {
    padding-left: $sidebar-width;
  }

  @include mq('desktop-sm') {
    padding-left: $sidebar-width-collapsed;
  }
}

.content {
  min-height: 100vh;
  background-color: $color-app-bg;
  display: flex;
  flex-direction: column;
}

.content--modal {
  pointer-events: all;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.layout--background {
  @include clearfix;

  @include mq('desktop') {
    padding-left: 20%;
  }

  &__sidebar {
    width: 20%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #42b357;
    display: none;

    @include mq('desktop') {
      display: block;
    }
  }
}

.layout--centered {
  display: flex;
  min-height: 100vh;
  align-items: center;

  &__content {
    flex-grow: 1;
  }
}

.layout--narrow {
  flex: 1;
}

.layout--narrow__container {
  margin: 0 auto;
  max-width: 480px;
  box-sizing: content-box;

  @include mq('mobile') {
    padding: 0 10px 20px;
  }
}

.layout--boxed {
  text-align: center;

  @include mq('desktop') {
    margin-top: 150px;
  }
}

.layout__heading {
  font-size: 24px;
  margin-bottom: 0;
}

.layout__lead {
  font-size: 16px;
  color: $gray-dark;
}

@media all and (-ms-high-contrast:none) {
  *::-ms-backdrop, .layout--centered { height: 100vh; } /* IE11 */
}

.main-content {
  max-width: 1200px;
  width: 100%;
  padding-top: 35px;
  padding-bottom: 35px;
}
