.skeleton {
  display: inline-block;
  vertical-align: middle;
  background-color: $skeleton-background-color;

  &--line {
    width: 100%;
    height: 10px;
    border-radius: 4px;
  }

  &--circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
