.people-list {
  @extend .nav;
  @extend .nav-stacked;

  &__info {
    padding-left: 12px;
  }

  &__label {
    font-size: 16px;
  }

  &__position {
    font-size: 12px;
    color: $gray-dark;
  }

  &__heading {
    padding: 10px 10px 10px 20px;
    color: $gray-dark;
  }

  &__letter {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: $text-color;
  }

  &__item {
    > :only-child {
      min-height: 69px;
    }

    .icon {
      margin-left: 10px;

      @include mq('small') {
        margin-left: 0;
      }
    }
  }
}

.people-list__item--inactive {
  color: $gray;
  background-color: $state-danger-bg;

  .people-list > & > :only-child {
    background-color: $state-danger-bg;
  }

  .item-list__primary,
  .people-list__position {
    color: $gray;
  }
}
