.sg-main {
  min-height: 100vh;
  padding-bottom: 100px;
}

.sg-color {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #ececec;
}

.sg-margin {
  margin-top: 20px;
}

.sg-header {
  text-align: center;

  h1 {
    margin-top: 0;
  }
}

.sg-h2 {
  font-size: 20px;
  margin-top: 60px;
  text-transform: uppercase;
  font-weight: bold;
  color: #ff3f20;
}

.sg-container {
  width: 100%;
  overflow: auto;
}
