html {
  overflow-y: scroll;
}

:first-child {
  margin-top: 0;
}

p, ul {
  margin-top: ($line-height-computed / 2);
  margin-bottom: 0;
}

ul ul {
  margin-top: 0;
}
