.toggle-buttons {
  user-select: none;
  display: inline-block;
  cursor: pointer;

  label {
    margin-bottom: 0;
  }

  > :first-child,
  :first-child > .toggle-button {
    border-left-width: 1px;
    @include border-left-radius(5px);
  }

  > :last-child,
  :last-child > .toggle-button {
    @include border-right-radius(5px);
  }
}

.toggle-button,
.toggle-buttons label {
  cursor: pointer;
}

.toggle-button {
  font-size: 14px;
  min-width: 100px;
  padding: 5px 15px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  border: 1px solid $brand-secondary;
  border-left-width: 0;
  color: $brand-secondary;

  &:hover,
  &:active,
  &:focus {
    color: $brand-secondary;
  }
}

.custom-control-input:checked ~ .toggle-button.custom-control-indicator,
.toggle-button--active {
  color: #fff;
  background-color: $brand-secondary;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
  }
}
