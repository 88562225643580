.no-gutter--left {
  padding-left: 0 !important;
}

.no-gutter--right {
  padding-right: 0 !important;
}

.no-gutter {
  @extend .no-gutter--left;
  @extend .no-gutter--right;
}

.small-gutter--left {
  padding-left: 5px !important;
}

.small-gutter--right {
  padding-right: 5px !important;
}

.small-gutter {
  @extend .small-gutter--left;
  @extend .small-gutter--right;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.d-inline-block {
  display: inline-block;
}
