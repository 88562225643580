.date-switcher {
  user-select: none;
  padding: 0 20px;
  flex: 1;
}

.date-switcher__date {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: bold;
  margin: 0;
}

.date-switcher__btn {
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  .icon {
    fill: $gray;
  }

  &:hover {
    background: #f7f7f7;

    > .icon {
      fill: $gray-dark;
    }
  }
}
