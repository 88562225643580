.symmetric-layout {
  .control-label {
    font-size: 16px;
    font-weight: normal;
    color: $gray-dark;
  }

  @include mq('tablet-*') {
    & + .symmetric-layout {
      margin-top: $symmetric-layout-vertical-whitespace;
    }
  }

  @include mq('*-phablet') {
    .has-error {
      .control-label {
        margin-top: 5px;
        font-size: 12px;
        color: $brand-danger;
      }
    }
  }
}

.symmetric-layout__group {
  margin: 15px auto 0;

  &:first-child:not(.symmetric-layout__group--separated) {
    margin-top: 0;
  }

  &--separated {
    padding-top: $symmetric-layout-vertical-whitespace;
    margin-top: $symmetric-layout-vertical-whitespace;
    border-top: $content-box-border;
  }

  @include mq('tablet-*') {
    margin-top: $symmetric-layout-vertical-whitespace;
  }
}

@include mq('tablet-*') {
  .symmetric-layout__group--horizontal {
    .symmetric-layout__row {
      display: flex;
      align-items: stretch;
    }

    .symmetric-layout__col {
      width: $symmetric-layout-offset-columns-width;
      position: relative;

      & + .symmetric-layout__col {
        margin-left: 20px;

        &:not(.symmetric-layout__col--sm) {
          width: $symmetric-layout-center-column-width;
        }
      }
    }

    .symmetric-layout__col--sm {
      width: $symmetric-layout-center-column-width / 2;
    }

    .symmetric-layout__col--wide {
      flex: 1;
      margin: 0 200px;
    }
  }
}

@include mq('tablet-*') {
  .symmetric-layout__group--separated {
    margin-top: 40px;
    padding-top: 32px;
  }
}

.control-label--wrapper-baseline {
  line-height: 40px;
  align-self: flex-start;
}
