.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index-overlay-sidebar;

  background-color: $overlay-color;

  &--modal {
    z-index: $z-index-overlay-modal;
  }
}

.overlay-container--active {
  &, body {
    overflow: hidden !important;
    position: relative;
    height: 100%;
  }
}
