.list-postfix {
  height: 60px;
  font-size: 14px;
  font-weight: normal;
  border-top: $content-box-border;
  border-radius: 0;

  &:hover {
    border-top: $content-box-border;
  }
}

a.list-postfix,
label.list-postfix {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.list-postfix__icon-wrapper {
  margin: -3px 0 -15px;
}

.list-postfix--unseparated {
  border-top: none !important;
}

tr.list-item--hovered {
  > td:first-child {
    border-left: $hover-border-width solid transparent;
  }

  &:not(.skeleton-row):hover {
    background-color: $color-highlight;

    > td:first-child {
      border-left-color: $brand-primary;
    }
  }
}

.item-list {
  @extend %list;
  margin-top: 45px;

  @include mq('small') {
    margin-top: 25px;
  }
}

.item-list__header {
  border-bottom: $default-border;
  padding: 20px;

  h2 {
    font-size: 20px;
    margin: 0;
  }
}

.item-list__item {
  @extend %card;
  padding-top: 20px;
  padding-bottom: 20px;

  @include mq('small') {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mq('desktop') {
    &:hover:not(div) {
      .item-list__arrow {
        fill: $text-color;
      }
    }
  }
}

.item-list__primary {
  font-size: 16px;
  color: $text-color;
  display: block;

  @include mq('small') {
    & {
      font-size: 14px;

      > b {
        font-size: 16px;
      }
    }
  }
}

.item-list__secondary {
  color: $gray-dark;
  display: block;

  .icon {
    margin-right: 5px;
  }
}

.item-list__ternary {
  color: $gray-dark;
  display: block;
}

.item-list__item--inactive {
  * {
    color: $gray;
  }

  cursor: not-allowed;
}

.item-list__arrow,
.item-list__back {
  transition: .1s ease-in-out;
  fill: $gray;
}

.item-list__back:hover {
  @include mq('desktop') {
    fill: $text-color;
  }
}

.item-list {
  > :not(:first-child) {
    margin-top: 20px;
  }
}

.item-list__item--bordered {
  border: $default-border;
}

.item-list__item--compact {
  padding: 10px 20px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.list__item {
  padding: 0 30px;
  cursor: pointer;
  min-height: 60px;
  background-color: #fff;

  > a {
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  @include mq('small') {
    padding: 0 20px;
  }

  &:hover {
    @extend .list-item-highlight;
  }

  @include mq('desktop') {
    &:hover {
      background-color: $color-highlight;
    }
  }
}

.list__item--bordered {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: $default-border;
}

.list__item--separated + .list__item--separated {
  padding-top: 10px;
  border-top: $default-border;
}

.list__item--deemphasized {
  background-color: $table-head-bg;
}

.list__item--raised {
  position: relative;
  background-color: #fff;
  overflow: hidden;

  &:before {
    @extend .list-item-highlight;

    width: 5px;
  }

  &:hover:before {
    width: 5px;
  }
}

.list--rounded {
  .list__item {
    &:first-child {
      border-top-right-radius: $default-border-radius;
      border-top-left-radius: $default-border-radius;
    }

    &:last-child {
      border-bottom-right-radius: $default-border-radius;
      border-bottom-left-radius: $default-border-radius;
    }
  }
}

.wrapper-circle {
  padding: 5px;
  border-radius: 50%;
  display: inline-block;
  line-height: 1px;
}

.wrapper-circle--bordered {
  border: 1px solid;
  padding: 4px;
}

.wrapper-circle--lg {
  padding: 7px;
}

.wrapper-circle--bordered-primary {
  border-color: $brand-primary;
}

.wrapper-circle--bordered-danger {
  border-color: $brand-danger;
}

.wrapper-circle--bordered-success {
  border-color: $brand-success;
}

.wrapper-circle--bordered-gray {
  border-color: $gray;
}

.wrapper-circle--opaque {
  background-color: #fff;
}

.wrapper-circle--success {
  border-color: transparent;
  background-color: $brand-success;

  > svg {
    fill: #fff;
  }
}

.hover-primary:hover {
  > .wrapper-circle {
    border-color: transparent;
    background-color: $brand-primary;

    > svg {
      fill: #fff;
    }
  }
}

.hover-danger:hover {
  > .wrapper-circle {
    border-color: transparent;
    background-color: $brand-danger;

    > svg {
      fill: #fff;
    }
  }
}

.hover-success:hover {
  > .wrapper-circle {
    border-color: transparent;
    background-color: $brand-success;

    > svg {
      fill: #fff;
    }
  }
}

.wrapper-circle--inactive {
  background-color: $gray-light !important;
}

.intro {
  p {
    font-size: 14px;
    color: $gray-dark;
    margin: .5rem 0 0;
  }
}

.intro h2,
.intro__header {
  margin: 0;
  font-size: 18px;
}

.inline-list {
  margin: 0;
  font-size: 12px;
  color: $text-color;
}

.inline-list__header {
  color: $gray-dark;
}

.link-list {
  > a + a {
    margin-left: 1em;
  }
}
