.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  max-width: $sidebar-max-width;
  max-height: 100vh;
  overflow-y: auto;
  background-color: $color-dark-bg;
  z-index: $z-index-sidebar;
  display: flex;
  flex-direction: column;

  @include mq('*-tablet') {
    @include transition(all 300ms ease-in-out);

    &.shown {
      box-shadow: 4px 0 10px -2px rgba(#000, 0.3);
    }
  }

  &,
  a {
    color: $sidebar-text-color;
  }

  @include mq('desktop') {
    width: $sidebar-width;
  }

  @include mq('desktop-sm') {
    width: $sidebar-width-collapsed;
  }

  @include mq('*-tablet') {
    width: 33%;
    transform: translateX(-100%);
  }

  @include mq('*-phablet') {
    width: 75%;
    transform: translateX(-100%);
  }

  &.shown {
    transform: translateX(0);
  }
}

.sidebar__nav {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.sidebar__item--spacer {
  flex-grow: 1;
}

.sidebar__item--pushed {
  & + & {
    border-top: 1px solid $sidebar-border-color;
  }
}

.sidebar__item,
.sidebar__brand {
  font-weight: bold;
  padding: $sidebar-padding - 1px $sidebar-padding $sidebar-padding - 3px;
}

.sidebar__brand {
  @include mq('desktop-sm') {
    padding: 0;
    text-align: center;
  }
}

.sidebar__item,
.sidebar__brand {
  border-bottom: 1px solid $sidebar-border-color;
}

.sidebar__brand {
  padding-top: 0;
  padding-bottom: 0;
  min-height: $header-height;
  line-height: $header-height - 3px;
}

.sidebar__nav > li {
  margin: 0;
}

.sidebar__item {
  width: 100%;
  display: block;
  border: solid transparent;
  border-width: 1px 0;
  background-color: transparent;
  text-align: left;

  .badge.pull-right {
    margin-top: 6px;
  }

  > .icon {
    margin: -2px 16px 0 0;
    fill: $sidebar-text-color;
  }

  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }

  @include mq('desktop-sm') {
    > .icon {
      margin-right: 0;
    }

    .sidebar__label {
      display: none;
    }
  }
}

.sidebar__item {
  @include mq('desktop-sm') {
    padding: 0;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
}

.sidebar__item:hover,
.sidebar__item--active {
  text-decoration: none;
  border-color: $sidebar-border-color;
  background-color: $color-dark-bg-hover;
  color: #fff;

  > .icon {
    fill: $brand-primary;
  }

  @include mq('desktop') {
    border-left: $sidebar-left-border-width solid $sidebar-border-color-light;
  }

  @include mq('desktop-sm') {
    padding-right: $sidebar-left-border-width;
  }

  @include mq('desktop-lg') {
    padding-left: $sidebar-padding - $sidebar-left-border-width;
    background-color: $color-dark-bg-hover;
  }
}

.sidebar__nav > li:first-child > .sidebar__item {
  border-top-width: 0;
}

.sidebar__badge {
  float: right;
  display: block;
  padding: 0 5px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  border-radius: 10px;
  text-transform: uppercase;
  color: #fff;

  @include mq('desktop-sm') {
    float: none;
    height: 18px;
    margin-top: 3px;
    line-height: 18px;
    font-size: 11px;
    text-align: center;
  }
}

.sidebar__badge--pin {
  padding: 0;
  width: 25px;
  height: 20px;
  line-height: 19px;

  @include mq('desktop-sm') {
    width: 18px;
    height: 18px;
    margin-top: 0;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.sidebar__badge--sm {
  font-size: 10px;
  font-weight: bold;
}
