.settings-section {
  margin-bottom: 25px;

  @include mq('tablet-*') {
    margin-bottom: 30px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.settings-section__header-group {
  margin-bottom: $settings-section-whitespace / 2;

  &--spaced {
    margin-bottom: $settings-section-whitespace;
  }

  .settings-section__header {
    margin-left: $settings-section-whitespace;
  }
}

.settings-section__label {
  text-align: right;
}

.settings-section__header {
  @include mq('tablet-*') {
    font-size: 16px;
  }
}

.settings-section__description {
  margin-bottom: $settings-section-whitespace;

  &:last-child {
    margin-bottom: 0;
  }
}
