.modal-form {
  font-size: 16px;
}

.modal-form__heading {
  font-size: 1em;
}

.modal-form__list {
  @extend %list;

  > li + li {
    margin-top: 10px;
  }
}
