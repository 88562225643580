.flex__row {
  display: flex;
  align-items: center;
}

.flex__row--wide {
  width: 100%;
}

.flex__row--space-between {
  justify-content: space-between;
}

.flex__row--flex-end {
  justify-content: flex-end;
}

.flex__row--flex-start {
  justify-content: flex-start;
}

.flex__row--column-reverse {
  flex-direction: column-reverse !important;
}

.flex__row--reverse {
  flex-direction: row-reverse;
}

.flex__row--center {
  justify-content: center;
}

.flex__row--wrap {
  flex-wrap: wrap;
}

.flex__row--stretched {
  align-items: stretch;
}

.flex__col--flex {
  display: flex;
}

.flex__col--separated {
  padding: 0 20px;
}

.flex__col--wide {
  flex-grow: 1;
}

.flex__col--stretched {
  align-self: stretch;
}

.flex__item {
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.flex__row--vertical {
  flex-direction: column;

  > .flex__col {
    display: flex;
    align-self: stretch;
    justify-content: center;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    > .btn {
      width: 100%;
    }
  }

  &.flex__row--column-reverse {
    > .flex__col {
      margin-bottom: 10px;

      &:first-child { // in reversed order first DOM element is displayed last
        margin-bottom: 0;
      }
    }
  }
}

.self-end {
  align-self: flex-end;
}

.self-start {
  align-self: flex-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow {
  flex-grow: 1;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-flex-start {
  align-items: flex-start !important;
}

.shrink-0 {
  flex-shrink: 0;
}

.mw0 {
  min-width: 0;
}
