.alert {
  padding: 15px;
  border-radius: 5px;
  display: flex;

  & + & {
    margin-top: 10px;
  }

  p {
    margin: 0;
  }

  &--info {
    @include alert($brand-info);
  }

  &--warning {
    @include alert(rgba(0, 0, 0, 0));
    border-color: #fbd5ac;
    background-color: #fef9f3;

    .icon {
      fill: #f39027;
    }

    .alert__title {
      color: #f39027;
    }
  }

  &--success {
    @include alert($brand-success);
    border-color: #e8eaed;
    background-color: rgba(242, 244, 247, 0.5);
  }

  &--danger {
    @include alert($brand-danger);
  }
}

.alert__title {
  display: block;
}

.alert__icon {
  margin-right: 10px;
}
