.label {
  display: inline-block;
  border: 1px solid $gray-light;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  padding: 0 15px;
  border-radius: 50px;
  vertical-align: middle;
  color: #999;
  background-color: transparent;
  line-height: 22px;

  &--success {
    @include label($brand-success);
  }

  &--danger {
    @include label($brand-danger);
  }

  &--warning {
    color: #725b00;
    border-color: #ffe991;
    background-color: #fff4c7;
  }

  &--info {
    @include label($brand-info);
  }

  &--disabled {
    @include label($gray-dark);
  }

  &--squared {
    border-radius: 3px;
  }
}

.label--solitary {
  margin-left: 0;
  margin-right: 0;
}

.label--pin {
  background-color: #fcd370;
  margin-left: 10px;
  height: 22px;
  line-height: 22px;
  padding: 0 10px;
  flex-shrink: 0;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 900;
}
