.fadeIn-enter {
  opacity: 0.01;
}

.fadeIn-enter.fadeIn-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.fadeIn-leave {
  opacity: 1;
}

.fadeIn-leave.fadeIn-leave-active {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}
