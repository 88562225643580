.nav {
  @extend %list;
  font-size: 16px;

  &,
  a {
    color: $text-color;
  }

  a {
    text-decoration: none;
  }

  &__title {
    font-size: 18px;
    display: block;
  }

  &__subtitle {
    font-size: 12px;
    display: block;
    color: $gray;
  }
}

.nav__item {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $gray-light;
  background-color: #fff;

  &,
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    color: $text-color;
  }
}

.nav-stacked {
  & {
    border-top: 1px solid $gray-light;
  }

  > li + li {
    margin: 0;
  }

  > li {
    > :only-child {
      display: flex;
      flex-direction: row;
      padding: 10px 20px;
      border-bottom: 1px solid $gray-light;
      background-color: #fff;
      border-radius: 0;

      &:hover,
      &:focus {
        padding-left: 17px;
        border-left: 3px solid $brand-primary;
        background-color: $color-highlight;

        .icon-gray {
          fill: $text-color;
        }
      }
    }

    &.disabled {
      cursor: not-allowed;

      >:only-child:hover,
      >:only-child:focus {
        border-left: 0;
        padding-left: 20px;
        background-color: #fff;
      }
    }

    @include mq('tablet-*') {
      &:last-child :only-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom: 0;
      }
    }
  }

  .flex__row {
    flex-grow: 1;
    min-height: 40px;
  }
}

.nav-stacked--rounded {
  @include mq('phablet-*') {
    margin-top: 30px;
    border-top: none;

    > li:first-child > a {
      @include border-top-radius(5px);
    }

    > li:last-child > a {
      border-bottom: none;
      @include border-bottom-radius(5px);
    }
  }
}

.nav-pills {
  font-size: 14px;
  text-transform: uppercase;

  > li > a {
    outline: 0;
    display: block;
  }

  > li + li {
    margin-top: 2px;
  }
}

.nav-pills__item {
  width: 100%;
  display: block;
  line-height: 1;
  padding-left: 20px;
  white-space: nowrap;
  height: $nav-pills-item-height;
  @include border-left-radius(5px);

  &--disabled {
    color: $gray;
  }
}

.nav-pills__shape {
  width: 19px;
  opacity: 0;
  vertical-align: middle;
  height: $nav-pills-item-height;
  margin-left: -1px;
}

.nav-pills__arrow {
  fill: #fff;
  width: 16px;
  height: 16px;
  position: relative;
  left: 2px;
}

.nav-pills__checkmark {
  fill: $brand-primary;
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.nav-pills {
  > .active {
    .nav-pills__item {
      font-weight: bold;
      background-color: $brand-primary;
      color: #fff;
    }

    .nav-pills__shape {
      opacity: 1;
      fill: $brand-primary;
    }
  }
}

.nav-pills--nested {
  padding-top: 5px;
  list-style-position: inside;
  list-style-type: none;
  padding-left: 20px;
}

.nav-pills__subitem {
  @extend %ellipsis;

  padding: 5px 0 5px 1px;
  text-transform: none;
  max-width: calc(100% - 20px);

  > a {
    &:before {
      content: '';
      display: inline-block;
      height: 10px;
      width: 15px;
      background-repeat: no-repeat;
      background-position: left center;
    }

    &.active {
      font-weight: bold;

      &:before {
        background-image: $arrow-right;
      }
    }
  }
}

.nav-pills {
  > li:not(.active):not(.disabled) > a:hover {
    .nav-pills__item {
      background-color: $nav-link-hover-bg;
    }

    .nav-pills__shape {
      opacity: 1;
      fill: $nav-link-hover-bg;
    }
  }

  > .disabled {
    cursor: not-allowed;
  }
}

.separator {
  border-top-color: $gray-light;
}
