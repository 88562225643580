.dropdown {
  position: relative;
  z-index: $z-index-popover;

  &__content-wrapper {
    width: 100%;
    position: absolute;
    bottom: -10px;

    &:before {
      $size: 75px;

      content: '';
      width: $size;
      height: $size;
      left: 50%;
      top: $size / -2;
      transform: translateX(-50%) rotate(45deg);
      position: absolute;
      display: none;

      @include mq('large') {
        display: block;
      }
    }
  }

  &__content {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    box-shadow: $dropdown-content-box-shadow;
    border-radius: $default-border-radius;
    transition: opacity .2s ease-out;
    overflow: hidden;

    @include mq('large') {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }

    &--visible {
      visibility: visible;
      opacity: 1;
      pointer-events: all;
    }
  }

  &__item {
    position: relative;
    color: $gray-dark;
    font-size: 16px;
    outline: none;
    white-space: nowrap;

    &:hover,
    a:focus {
      @extend .list-item-highlight;

      background-color: #f7f7f7;
      color: $gray-darker;
      outline: none;
    }

    > * {
      padding: 10px 20px;
      display: block;
    }

    > a {
      color: currentColor;
    }
  }

  &--open,
  &:focus-within {
    .dropdown__content {
      @extend .dropdown__content--visible;
    }

    .dropdown__toggle .btn {
      @extend .btn-primary:hover;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
    }
  }

  &:focus {
    outline: none;
  }
}
