.navbar {
  box-shadow: $navbar-box-shadow;
  background-color: #fff;

  .employee {
    text-align: left;
    white-space: nowrap;
  }

  > .flex__row {
    > .flex__col:first-child {
      width: $heading-button-width;
    }
  }

  &--bordered {
    border-top: 1px solid $gray-light;
    z-index: $z-index-navbar;
    position: relative;
  }

  &--spaced {
    padding: 10px 20px;
    margin-bottom: 20px;

    @include mq('phablet-*') {
      margin-bottom: 40px;
    }
  }

  &--flat {
    border-bottom: $default-border;
    box-shadow: none;
  }

  h1 {
    font-size: 20px;
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  + .container-sm .container-sm__group--highlighted:first-of-type {
    box-shadow: $navbar-box-shadow-inset;
  }
}

.navbar__row {
  @include mq('desktop') {
    padding-left: 20px;
  }

  > .tg__col {
    vertical-align: middle;
  }
}

.navbar__row + .navbar__row {
  border-top: $default-border;
}

.navbar__row--primary {
  height: 60px;

  @include mq('tablet-*') {
    height: $header-height;
  }
}

.navbar__logo {
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);

  @include mq('phone') {
    transform: translateX(-50%) scale(0.75);
  }
}

.navbar--flex {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search {
  padding: 0 20px;
  position: relative;

  &--active {
    .search__icon {
      color: $brand-primary;
    }
  }

  @include mq('*-phablet') {
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 100;

    display: none;
  }
}

.search__input {
  padding: 5px;
  border: none;
  font-size: 18px;
  vertical-align: 2px;

  @include mq('tablet-*') {
    margin-left: 10px;
  }

  @include mq('*-phablet') {
    width: 100%;
    height: 60px;
  }

  @include mq('phone') {
    height: 50px;
  }

  &:focus {
    outline: none;
  }
}

.search__icon {
  color: $gray;
  font-size: 20px;
  margin-right: 10px;
}

.search__reset {
  color: $gray;
}

.search__result {
  position: absolute;
  top: 66px;
  left: 0;
  z-index: 100;

  @include mq('*-tablet') {
    width: 100%;
    padding: 0 15px;
  }

  @include mq('desktop-*') {
    width: 515px;
    padding-left: 15px;
  }

  display: none;
}

.search__container {
  position: static;
}

.search__result .employee__position {
  @include mq('phone') {
    display: none;
  }
}

