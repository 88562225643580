.table {
  margin: 0;

  &--fixed {
    table-layout: fixed;
  }

  &--gap-fix {
    width: calc(100% + 0.5px);
    max-width: calc(100% + 0.5px);
  }

  > tbody,
  > thead {
    > tr {
      > td {
        padding: 10px 0;
        vertical-align: middle;
      }

      > th {
        font-weight: normal;
        border: 0;
        padding: 8px 0;
        vertical-align: middle;
      }

      &:first-child {
        > td {
          border-top: none;
        }
      }
    }

    > .table__row--spacious {
      > td,
      > th {
        padding-top: 19px;
        padding-bottom: 19px;
      }
    }
  }

  > thead > tr {
    > td,
    > th {
      background-color: $table-head-bg;
      border-bottom: $default-border;
    }
  }
}

.table__head {
  > tr > th,
  > tr > td {
    &:first-child {
      border-top-left-radius: $default-border-radius;
    }

    &:last-child {
      border-top-right-radius: $default-border-radius;
    }
  }
}

.table__row--selected {
  background-color: $color-selected;
}


%row-greyed-out {
  background-color: $state-danger-bg;

  .table__cell {
    color: $gray;
  }
}

.table__row--danger {
  @extend %row-greyed-out;

  .table__cell:first-child {
    position: relative;

    &:before {
      @extend %table-row-hover-line;
      background-color: $brand-danger;
    }
  }
}

.table__row--interactive {
  cursor: pointer;

  .table__cell:first-child {
    position: relative;

    &:before {
      @extend %table-row-hover-line;
    }
  }

  &:hover {
    background-color: $color-highlight;

    .table__cell:first-child:before {
      background-color: $brand-primary;
    }
  }
}

.table__row--inactive {
  @extend %row-greyed-out;
}

.table__row--wrapping {
  > td:first-child {
    padding-left: 30px !important;
  }

  > td:last-child {
    padding-right: 30px !important;
  }

  @include mq('small') {
    > td:first-child {
      padding-left: 20px !important;
    }

    > td:last-child {
      padding-right: 20px !important;
    }
  }
}

.table__row--marked {
  .table__cell:first-child {
    position: relative;

    &:before {
      @extend %table-row-hover-line;
      background-color: $brand-primary;
    }
  }
}

.table__cell {
  &--1 {
    width: 70px;
  }

  &--2 {
    width: 80px;
  }

  &--3 {
    width: 360px;
  }

  &--spacer-sm {
    width: $table-padding-sm-cell-width;
  }

  &--spacer-md {
    width: $table-padding-md-cell-width;
  }

  &--clickable:hover {
    cursor: pointer;

    a {
      text-decoration: underline;
    }
  }
}
