.grid-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}

.cards-list {
  min-height: 240px;
}

.contact-cards-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.cards-list__item {
  margin-bottom: 30px;
}

%card-transition {
  transition: .1s ease-in-out;
  transition-property: box-shadow, transform;
}

%card-shadow {
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

%card {
  display: flex;
  align-items: center;
  padding: 40px 30px;
  background-color: #fff;
  border-radius: 5px;
  color: $text-color;
  @extend %card-transition;

  &:hover:not(div),
  &:focus:not(div) {
    color: $text-color;
    text-decoration: none;
    @extend %card-shadow;
  }
}

.card {
  @extend %card;
  font-weight: bold;
  box-shadow: 0 0 3px 0 $card-shadow-color;

  &:hover {
    .card__type-icon {
      @extend .text-primary;
    }
  }

  .card__type-icon {
    fill: $gray;
    margin-right: 10px;
  }
}

.contact-card {
  @extend %card;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding: 20px 0;
  box-shadow: 0 0 3px 0 $card-shadow-color;

  @include mq('mobile') {
    margin: 0 10px;
  }

  &:hover {
    @extend %card-shadow;

    @include mq('desktop') {
      .contact-card-tooltip {
        display: block;
      }
    }
  }
}

.contact-card-tooltip {
  position: absolute;
  top: 20px;
  right: 27px;
  display: none;

  &:hover {
    .icon {
      fill: $link-hover-color;
    }
  }
}

.card__type-icon {
  transition: fill .1s ease-out;
}

.card__body,
.card__body > * {
  min-width: 0;
}

.card__body {
  margin: 0 1em 0 0;
  flex: 1;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.card__body .ellipsis {
  vertical-align: bottom;
}

.card__title {
  line-height: 1.62;
}

.card__suffix {
  @extend .text-gray;
}

.card__suffix--fixed {
  width: 130px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cards-list__item--block {
  border-left: 2px solid transparent;

  &:hover {
    background-color: $color-highlight;
    border-left-color: $brand-primary;
  }
}
