.profile-summary {
  &__joined {
    margin-top: -13px;
  }

  &__name {
    font-size: 24px;
    margin: 0;

    &--contact-card {
      font-size: 16px;
      font-weight: normal;
      color: $gray-darker;
      padding: 10px;
      word-break: break-word;
    }
  }

  &__position {
    margin: 0;
    font-size: 16px;
    font-weight: normal;
    color: $gray-dark;

    &--contact-card {
      min-height: 17px;
      font-size: 12px;
      padding: 0 10px;
    }
  }

  &__location {
    font-size: 16px;
    margin-top: 12px;
    font-weight: normal;

    > .icon {
      vertical-align: -6px;
    }
  }

  &__about {
    font-size: 16px;
    color: $gray-dark;
    line-height: 20px;
    margin-top: 10px;
  }

  &__details {
    margin-top: 17px;
  }

  h2 {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: normal;
  }

  .profile-pic {
    display: inline-flex;
  }
}

.profile-summary {
  &.l-horizontal {
    text-align: center;
    padding: 20px 0 15px;
  }
}

.profile-summary--sm {
  .profile-summary__details {
    margin-top: 8px;
  }

  .profile-summary__location {
    font-size: 14px;
    margin: 8px 0 0;
  }

  .profile-summary__joined {
    margin-top: -10px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 20px;
  }
}

.profile-summary--lg {
  .profile-summary__main {
    padding: 0 70px;
  }

  .profile-summary__section {
    margin-top: 32px;

    + .profile-summary__section {
      margin-top: 37px;
    }
  }
}

.profile-summary__bottom {
  padding: 30px 0;
}

.social-media-links {
  margin-top: 25px;
}

.social-media-icon {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;

  > .icon {
    fill: #fff;
  }
}

.initials {
  fill: #d9dfe8;
  color: #fff !important;
  text-decoration: none !important;
  border-radius: 50%;
  font-weight: bold;
  user-select: none;
  text-transform: uppercase;
  flex-shrink: 0;

  &--dimmed {
    fill: $gray-light;
  }
}

.opposed-nav {
  width: 100%;
  display: table;
  padding: 0 25px;
  font-size: 16px;

  &__item {
    display: table-cell;

    &--right {
      text-align: right;
      border-left: $default-border;

      .icon {
        margin-left: 15px;
      }
    }

    &--left {
      .icon {
        margin-right: 15px;
      }
    }

    > a {
      color: $gray-dark;
      text-decoration: none;

      > .icon {
        fill: $gray;
      }

      &:hover {
        color: $text-color;

        > .icon {
          fill: $brand-primary;
        }
      }
    }
  }
}
