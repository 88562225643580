.rs-container {
  font-size: 16px;
}

.rs__control {
  height: 40px;
  padding-left: 18px;
  border-color: $gray-light !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.rs__control--is-focused {
  border-color: $gray !important;
}

.rs__control--is-disabled {
  background-color: #fbfbfb !important;
  color: $text-color;
  box-shadow: none;
}

.rs__control--is-focused.rs__control--menu-is-open {
  box-shadow: none;
}

.rs__control--is-focused:not(.rs__control--menu-is-open) {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(187, 187, 187, 0.6);
}

.rs__value-container {
  padding: 0 !important;
}

.rs__single-value {
  max-width: 100% !important;
}

.rs__single-value {
  .label-inactive {
    font-style: italic;
    margin-left: .5em;
    color: $gray-dark;
    font-size: 80%;
  }
}

.rs__input {
  margin: 0 !important;
}

.rs__menu {
  overflow: hidden;
  margin-top: 0 !important;
  z-index: $z-index-dropdown !important;
  box-shadow: $dropdown-content-box-shadow !important;
}

.rs-container .rs__menu-list {
  padding-top: 0;
  padding-bottom: 0;
}

.rs__menu .rs__option {
  padding: 10px 18px 10px 15px;
  border-left: 3px solid transparent;
  color: $gray-dark;
  cursor: pointer;

  > small {
    display: block;
    font-size: 12px;
  }
}

.rs__option:active {
  background-color: $gray-light !important;
}

.rs__menu .rs__option--is-focused {
  background-color: transparent;
}

.rs__menu .rs__option--is-selected {
  background-color: #fbfbfb;
  color: $text-color;
}

.rs__menu .rs__option--is-focused {
  border-left-color: $brand-primary;
  background-color: #f7f7f7;
  color: $text-color;
}

.rs__option--separated {
  border-bottom: 1px solid $gray-light;
}

.rs__option--inactive {
  color: $gray !important;
}

.rs__indicators {
  margin-right: 10px;
}

.rs__indicator {
  cursor: pointer;
}

.rs__clear-indicator,
.rs__dropdown-indicator {
  @extend %input-indicator;
}

.rs__clear-indicator {
  right: 39px;

  svg {
    fill: $gray;
  }

  &:hover {
    svg {
      fill: $gray-dark;
    }
  }
}

.rs__dropdown-indicator {
  right: -1px;

  svg {
    fill: $gray-dark;
  }

  &:hover {
    svg {
      fill: $gray-darker;
    }
  }
}
