.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.text-align-right {
  text-align: right;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.ellipsis {
  @extend %ellipsis;
  display: inline-block;
  max-width: 100%;
}

.ellipsis--block {
  display: block;
}

.nowrap {
  white-space: nowrap !important;
}

.h3 {
  font-size: 24px !important;
}

.text-20 {
  font-size: 20px !important;
}

.text-18 {
  font-size: 18px !important;
}

.text-16 {
  font-size: 16px !important;
}

.text-14 {
  font-size: 14px !important;
}

.text-12 {
  font-size: 12px !important;
}

.text-10 {
  font-size: 10px !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.lh-1 {
  line-height: 1;
}
