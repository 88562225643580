.permissions-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.permissions-list__group {
  border-top: 1px solid $gray-light;
  padding: 15px 30px;
}

.permissions-list__group-heading {
  font-size: 16px;
  margin-bottom: 5px;
}

.permissions-list__item {
  margin-top: 10px;
  width: 50%;
  display: flex;

  &--not-permitted {
    color: $gray-dark;
  }
}

.permissions-list__account-owner {
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $gray-light;
}

.permissions-list__owner-data {
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 10px;
  font-size: 16px;
}
