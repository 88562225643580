@mixin circle($size) {
  width: $size;
  height: $size;
  line-height: $size;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
}

@mixin button-color ($primary) {
  &,
  &[disabled] {
    box-shadow: inset 0 0 0 1px $primary;
  }

  &[disabled]:hover {
    color: $primary;
  }

  &,
  &:focus {
    color: $primary;
    background-color: transparent;

    .dots__svg {
      fill: $primary;
    }
  }

  &:not([disabled]) {
    &:hover,
    &:hover:focus,
    &:hover:active {
      box-shadow: none;
      background: $primary;
      color: #fff;
    }
  }

  .icon {
    fill: $primary;
  }
}

@mixin label($color) {
  color: $color;
  border-color: rgba($color, 0.3);
  background-color: rgba($color, 0.1);
}

@mixin alert($color) {
  border: 1px solid rgba($color, 0.3);
  background-color: rgba($color, 0.1);

  .icon {
    fill: $color;
  }

  .alert__title {
    color: $color;
  }
}

@mixin triangle($color, $size, $direction) {
  border-style: solid;
  border-width: $size;
  border-color: transparent;

  @if $direction == 'top' {
    border-bottom-color: $color;
  }

  @else if $direction == 'bottom' {
    border-top-color: $color;
  }

  @else if $direction == 'left' {
    border-right-color: $color;
  }

  @else if $direction == 'right' {
    border-left-color: $color;
  }
}
