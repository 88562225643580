.progress {
  padding: 20px 30px;

  & + & {
    border-top: $default-border;
  }

  @include mq('large') {
    .wrapper-circle {
      margin-right: -10px;
    }
  }

  @include mq('small') {
    padding: 20px;
  }
}

.progress--link {
  display: block;
  position: relative;

  & + & {
    border-top: $default-border;
  }

  &:hover {
    @extend .list-item-highlight;

    background-color: $color-highlight;
    text-decoration: none;
  }

  .item-list__primary:hover {
    text-decoration: underline;
  }
}

.progress__details:not(:empty) {
  margin-bottom: 20px;
}

.progress-bar {
  $br: 100px;

  width: 100%;
  overflow: hidden;
  height: 4px;
  border-radius: $br;
  line-height: 0;

  > :first-child {
    border-top-left-radius: $br;
    border-bottom-left-radius: $br;
  }

  > :last-child {
    border-top-right-radius: $br;
    border-bottom-right-radius: $br;
  }

  > * {
    overflow: hidden;
  }
}

.progress-bar__value {
  height: 100%;
  max-width: 100%;
  vertical-align: top;
  display: inline-block;
}
