.expandable-nav__header {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 56px;
}

.expandable-nav__btn-wrapper {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}

.expandable-nav__main {
  width: 100%;
  position: absolute;
  top: 116px; // @todo change to static position
  bottom: 0;
  z-index: $z-index-dropdown;
  background-color: $overlay-color;
  overflow: auto;
}

.expandable-nav__list {
  @extend %list;
}

.expandable-nav__item {
  width: 100%;
  @extend %btn-pure;
  @extend .nav__item;
  font-size: 18px;
  padding: 15px 20px 15px 0;

  > .icon {
    visibility: hidden;
  }
}

.expandable-nav__item--link {
  border-left: $hover-border-width solid transparent;

  &:hover,
  &:active,
  &.active{
    border-left-color: $brand-primary;
  }

  .expandable-nav__icon-placeholder {
    width: $expandable-nav-icon-placeholder-size - $hover-border-width;
  }
}

.expandable-nav__item--nested {
  display: flex;
  justify-content: space-between;
  padding-left: $expandable-nav-icon-placeholder-size;
  font-size: 16px;
  color: $gray-dark;
}

.expandable-nav__item--active,
.expandable-nav__item--active:hover {
  background-color: $brand-primary;
  color: #fff;

  > .icon {
    fill: #fff;
    visibility: visible;
  }
}

.expandable-nav__icon-placeholder {
  text-align: center;
  display: inline-block;
  width: $expandable-nav-icon-placeholder-size;
}

.expandable-nav__label {
  padding: 6px 0;
  font-size: 16px;
  display: inline-block;
}
