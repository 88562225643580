.contact-info {
  font-size: 16px;

  &__row {
    width: 100%;
    padding: 10px 0 9px;
    border-bottom: $content-box-border;

    &--contact-card {
      padding: 10px 20px;
      border: none;
    }
  }

  &__label {
    font-size: 12px;
    text-transform: uppercase;
    color: $gray;
  }

  &__content {
    display: inline-block;
    vertical-align: middle;
    flex-grow: 1;

    > * {
      display: block;
    }
  }

  &__value,
  &__value:hover {
    text-decoration: none;
    color: $text-color;
  }
}

.contact-info--lg {
  border-top: $default-border;
}

.contact-info--sm {
  font-size: 14px;

  .contact-info__row {
    padding-left: 20px;
    padding-right: 20px;
  }

  .contact-info__label {
    font-size: 10px;
  }

  .contact-info__value {
    margin-top: 5px;
  }
}

.link-circle {
  border-radius: 50%;
  border: 1px solid $gray-light;
  padding: 7px;
  display: inline-block;
  line-height: 1;

  .icon {
    fill: $brand-primary;
  }

  &:hover {
    border-color: $brand-primary;
    background-color: $brand-primary;

    .icon {
      fill: #fff;
    }
  }
}
