.secondary-nav {
  margin: 0;
  padding: 0 20px;
  list-style-type: none;
  font-size: 16px;
}

.secondary-nav__item {
  display: inline-block;

  & + & {
    margin-left: 60px;
  }
}

.secondary-nav__link {
  padding: 20px 0;
  display: inline-block;
  color: $gray-dark;

  &:hover,
  &:focus,
  &--active {
    color: $gray-darker;
    text-decoration: none;
  }

  &:hover,
  &--active {
    padding-bottom: 17px;
    border-bottom: 3px solid $brand-primary;
  }
}
