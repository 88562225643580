.calendar-sm {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: $gray-dark;
}

.calendar-sm__body {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex: 1;
}

.calendar-sm__row {
  display: flex;
  align-items: center;
}

.calendar-sm__body > .calendar-sm__row {
  flex: 1;
}

.calendar-sm__day {
  flex: 1;
}

.calendar-sm__link {
  width: 100%;
  border-radius: 50%;

  &,
  &:hover,
  &:focus {
    color: $gray-dark;
    text-decoration: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 350px) {
    width: 40px;
    height: 40px;
  }
}

.calendar-sm__link--today {
  border: 2px solid $brand-secondary;

  &,
  &:hover,
  &:focus {
    color: $brand-secondary;
  }
}

.calendar-sm__link--active {
  font-weight: bold;
  background-color: $brand-secondary;

  &,
  &:hover,
  &:focus {
    color: #fff;
  }
}

.calendar-sm__weekdays {
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  min-height: $calendar-sm-header-height;
}

.calendar-sm__link--inactive {
  color: $gray-light;
}

.calendar-sm__button {
  border-radius: 50%;
}

.calendar-sm__aspect-ratio {
  position: relative;

  &::before {
    padding-top: 100%;
    display: block;
    content: "";

    @include mq('phablet') {
      padding-top: 80%;
    }
  }
}

.calendar-sm__cell {
  padding: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: stretch;
  justify-content: center;

  @include mq('phablet-*') {
    align-items: center;
  }

  font-size: inherit;
}

.calendar-sm-container {
  min-height: 100vh;
}
