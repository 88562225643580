.container-sm {
  margin: 0 auto;
  width: $screen-sm-min;
  max-width: 100%;
}

.container-xs {
  margin: 0 auto;
  max-width: 400px;
}

.container-sm__group {
  padding: 20px;

  &--highlighted {
    background-color: #fff;

    @include mq('!-phone') {
      border-radius: 5px;
    }
  }

  @include mq('!-phone') {
    &:first-child {
      margin-top: 25px;
    }
  }
}

.container-sm__group--separated {
  & + & {
    margin-top: 25px;
  }
}

.group {
  padding: 0 20px;
}

.group--separated {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: $default-border;
}

.container-sm {
  > .row {
    margin-left: 0;
    margin-right: 0;

    > .col-xs-12 {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
