.btn {
  border: none;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.3;
  border-radius: 5px;
  padding: 9px 20px 11px;
  background-color: transparent;

  &[disabled] {
    opacity: 0.5;
  }
}

.btn-lg {
  font-size: 20px;
  padding: 17px 40px;
}

.btn-md {
  font-size: 18px;
  padding: 15px 20px;
  line-height: 1;
}

.btn-sm {
  font-size: 14px;
  padding: 7px 12px;
}

.btn-xs {
  font-size: 14px;
  padding: 3px 10px;
  font-weight: normal;
}

.btn-primary {
  background: $brand-primary;

  &:hover {
    background: $brand-primary-alt;
  }

  svg {
    fill: #fff;
  }
}

.btn-secondary {
  @include button-color($brand-secondary);
}

.btn-ternary {
  @include button-color($gray-dark);
}

.btn-danger {
  @include button-color($brand-danger);
}

.btn-warning {
  @include button-color($brand-warning);
}

.btn-squircle {
  @extend .btn-primary;

  padding: 0;
  border-radius: 15px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  }
}

.btn-link {
  color: $gray-dark;
  font-weight: normal;

  &:hover,
  &:active,
  &:focus {
    color: $gray-darker;
    text-decoration: none;
  }
}

%btn-pure {
  border: none;
  font-size: 14px;
  background: none;
  font-weight: normal;
  background-color: transparent;
}

.btn-icon {
  @extend %btn-pure;
  padding: 10px;

  .icon {
    fill: $gray;
  }

  &:hover {
    .icon {
      fill: $gray-dark;
    }
  }

  &:focus,
  &:active,
  &:active:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn-pure {
  padding: 0;
  @extend %btn-pure;

  &:active {
    @include box-shadow(none);
  }
}

.heading__btn {
  width: $heading-button-width;
  text-align: center;

  &:not(button) {
    @extend .flex__item;
  }

  &--bordered-left {
    border-left: $default-border;
  }

  &--bordered-right {
    border-right: $default-border;
  }
}

[class*=btn--icon] .icon {
  margin-top: -2px;
}

.btn--icon-right {
  padding-right: 15px;

  .icon {
    margin-left: 10px;
  }
}

.btn--icon-left {
  padding-left: 15px;

  .icon {
    margin-right: 10px;
  }
}

.btn-group--inline {
  display: inline-block;

  .btn + .btn {
    margin-left: 15px;
  }
}

.btn-pill {
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 100px;
  font-weight: normal;
  border: $default-border;
  color: $text-color;
}

.btn-default {
  background-color: transparent;
  border: $default-border;
  color: #9a9a9a;

  &:hover,
  &:focus {
    color: #333;
    border-color: #999;
    background-color: transparent;
  }

  &:active,
  &:focus:active {
    background-color: #f7f7f7;
  }
}

.btn--loading {
  position: relative;
  color: transparent !important;
  cursor: progress !important;

  &:not(.btn-primary) {
    background: none !important;
  }

  &.btn-primary {
     background: linear-gradient(#00cc77, #0abf74) !important;
  }

  .icon {
    visibility: hidden;
  }

  &[disabled] {
    opacity: 1;
  }
}

.dots {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: inherit;
  border-radius: inherit;
  border: inherit;
  box-shadow: inherit;
}

.dots__svg {
  width: 32px;
  height: 32px;
}
