.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-modal;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: none;
}

.modal {
  position: relative;
  width: 570px;
  margin: 175px auto 0;
  background-color: #fff;
  border-radius: 5px;
  pointer-events: all;

  @include mq('*-phablet') {
    width: 420px;
    margin-top: 0;
  }

  @include mq('phone') {
    width: auto;
  }
}

.modal__header {
  padding: 10px 10px 0 20px;
}

.modal__heading {
  font-size: 20px;
  display: block;
}

.modal__body {
  padding: 0 20px 25px;
  font-size: 14px;

  .modal__heading {
    margin-bottom: 20px;
  }

  @include mq('tablet-*') {
    font-size: 16px;
  }

  @include mq('tablet-*') {
    padding: 20px 40px 30px;
  }
}

.modal__footer {
  padding: 20px 20px 10px;
  border-top: 1px solid $gray-light;

  @include mq('*-phablet') {
    .btn:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  @include mq('tablet-*') {
    padding: 20px 30px;
  }
}

.modal-open {
  overflow: hidden;
}
