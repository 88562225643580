.popover {
  width: 340px;
  margin-top: 50px;
  background-color: #fff;
  @include box-shadow(0 5px 10px rgba(#000, 0.15));
}

.popover__title {
  position: relative;
  @include border-top-radius(4px);
  padding: 10px $popover-padding;
  color: #fff;

  &--default {
    background-color: $color-dark-bg;
  }

  &--primary {
    background-color: $brand-primary;
  }
}

.popover__content {
  overflow: hidden;
  @include border-bottom-radius(4px);
}

.popover__module {
  @include clearfix;
  padding: 10px $popover-padding;

  &:not(:first-child) {
    border-top: 1px solid #ebebeb;
  }
}

.popover__arrow {
  left: 50%;
  top: 0;
  transform: translate(-50%, -100%);
  position: absolute;
  z-index: 100;

  &--default {
    > path {
      fill: $color-dark-bg;
    }
  }

  &--primary {
    > path {
      fill: $brand-primary;
    }
  }
}
