//==============================================================
//== Base
//==============================================================

@import "~bootstrap/scss/functions";

// Fonts
$font-family-base:         'Lato', sans-serif;
$headings-font-family:     $font-family-base;
$headings-font-weight:     bold;

// Colors
$gray:                   #bbb;
$gray-light:             #dcdcdc;
$gray-lighter:           #999;
$gray-dark:              #747474;
$gray-darker:            #333;

$brand-primary:          #14bd83;
$brand-primary-alt:      #16cf8f;
$brand-secondary:        #5c63fb;
$brand-success:          #18c559;
$brand-danger:           #ff3f20;
$brand-warning:          #ff8a00;
$brand-info:             #38b0f9;
$brand-beta:             #8338f9;

$color-app-bg:           #f2f4f7;
$color-highlight:        mix($brand-primary, #fff, 5%);
$color-selected:         mix($brand-secondary, #fff, 5%);
$color-dark-bg:          #2a2e33;
$color-dark-bg-hover:    #202327;

$color-type-vacation:    #41beee;
$color-type-health:      #f21942;

$text-color:             $gray-darker;
$link-color:             $brand-secondary;
$link-hover-color:       $brand-secondary;
$input-color:            $gray-dark;
$text-blue:              $brand-secondary;

$state-danger-text:      #ff3f20;
$state-danger-bg:        #f9f9f9;

$default-border-color:    $gray-light;
$default-border:          1px solid $default-border-color;
$default-border-radius:   5px;
$border-radius-lg:        10px;

$input-group-addon-bg:   transparent;

$overlay-color:          rgba($color-dark-bg, 0.8);

// Sizes
$padding-large-vertical: 12px;
$input-height-base:      40px;
$input-sm-height-base:   30px;
$data-form-label-width:  220px;
$heading-button-width:   64px;
$hover-border-width:     3px;

// Header
$header-height:          80px;

// Layers
$z-index-dropdown:            1000;
$z-index-navbar:              1010;
$z-index-popover:             1020;
$z-index-overlay-sidebar:     1050;
$z-index-sidebar:             1060;
$z-index-overlay-modal:       1070;
$z-index-modal:               1080;
$z-index-notification:        1090;

// Sidebar
$sidebar-width:                  220px;
$sidebar-max-width:              300px;
$sidebar-padding:                20px;
$sidebar-border-color:           #393d42;
$sidebar-border-color-light:     #636568;
$sidebar-left-border-width:      3px;
$sidebar-text-color:             #84898f;
$sidebar-width-collapsed:        60px;

// Dashboard
$dashboard-border-radius:    $default-border-radius;

// Requests
$request-border:             $default-border;

// Calendar
$calendar-column-width:         120px;
$calendar-first-column-width:   258px;
$calendar-border-color:         $default-border-color;
$calendar-sm-header-height:     30px;

// Popover
$popover-padding:               20px;

// Tooltip
$tooltip-color:               $text-color;
$tooltip-bg:                  #fff;

// Navs
$nav-link-hover-bg:                         #e4e6eb;
$nav-link-padding:                          5px 15px !default;
$nav-pills-active-link-hover-bg:            $brand-primary;
$nav-pills-item-height:                     36px;
$expandable-nav-icon-placeholder-size:      50px;
$navbar-box-shadow:                         0 4px 2px -2px rgba(#000, 0.1);
$navbar-box-shadow-inset:                   $navbar-box-shadow inset;

// Content Box
$content-box-border:               $default-border;
$content-box-header-font-size:     20px;
$content-box-header-height:        69px;

// Settings section
$settings-section-whitespace: 20px;

// Skeletons
$skeleton-background-color:   #f2f4f7;

// Symmetric layout
$symmetric-layout-center-column-width:      400px;
$symmetric-layout-offset-columns-width:     calc(50% - #{$symmetric-layout-center-column-width / 2});
$symmetric-layout-vertical-whitespace:      30px;

// Placeholder card
$placeholder-card-width: 370px;

// Card
$card-shadow-color:     rgba(#000, .15);

// Table
$table-padding-md-cell-width: 30px;
$table-padding-sm-cell-width: 20px;
$table-head-bg: #fafafa;
$table-foot-bg: rgba(220, 220, 220, 0.1);

// Dropdowns
$dropdown-content-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);

// Breakpoints
$screen-xs: 320px;   // phone
$screen-sm: 600px;   // phablet
$screen-md: 1024px;  // tablet
$screen-lg: 1280px;  // desktop
$screen-xl: 1440px;  // desktop hd

$grid-breakpoints: (
  0: 0,
  xs: $screen-xs,
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg,
  xl: $screen-xl
);

$screen-xl-min: $screen-xl;
$screen-lg-max: ($screen-xl-min - 1);

// Spacing
$spacer: 5px;
$spacers: (
  0: 0,
  1: ($spacer * 1),
  2: ($spacer * 2),
  3: ($spacer * 3),
  4: ($spacer * 4),
  5: ($spacer * 5),
  6: ($spacer * 6),
  7: ($spacer * 7),
  8: ($spacer * 8),
  9: ($spacer * 9),
  10: ($spacer * 10)
);

// Shadows
$shadow-lg: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Form validation icons
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

$form-icon-dropdown-color: $text-color !default;
$form-icon-dropdown: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$form-icon-dropdown-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-control-checked-indicator-color:      #fff;
$custom-checkbox-checked-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpolygon fill='#{$custom-control-checked-indicator-color}' points='7.2,15.9 0.2,8.7 2.7,6 7.2,10.5 15.3,2.3 18,5'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-control-toggle-on: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNDAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCA0MCAyMCI+ICA8ZGVmcz4gICAgPGNpcmNsZSBpZD0idG9nZ2xlX29uLWIiIGN4PSIzMCIgY3k9IjEwIiByPSI4Ii8+ICAgIDxmaWx0ZXIgaWQ9InRvZ2dsZV9vbi1hIiB3aWR0aD0iMTI1JSIgaGVpZ2h0PSIxMjUlIiB4PSItMTIuNSUiIHk9Ii02LjIlIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giPiAgICAgIDxmZU9mZnNldCBkeT0iMSIgaW49IlNvdXJjZUFscGhhIiByZXN1bHQ9InNoYWRvd09mZnNldE91dGVyMSIvPiAgICAgIDxmZUdhdXNzaWFuQmx1ciBpbj0ic2hhZG93T2Zmc2V0T3V0ZXIxIiByZXN1bHQ9InNoYWRvd0JsdXJPdXRlcjEiIHN0ZERldmlhdGlvbj0iLjUiLz4gICAgICA8ZmVDb2xvck1hdHJpeCBpbj0ic2hhZG93Qmx1ck91dGVyMSIgdmFsdWVzPSIwIDAgMCAwIDAgICAwIDAgMCAwIDAgICAwIDAgMCAwIDAgIDAgMCAwIDAuMiAwIi8+ICAgIDwvZmlsdGVyPiAgPC9kZWZzPiAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgPHJlY3Qgd2lkdGg9IjQwIiBoZWlnaHQ9IjIwIiBmaWxsPSIjMEM3IiByeD0iMTAiLz4gICAgPHVzZSBmaWxsPSIjMDAwIiBmaWx0ZXI9InVybCgjdG9nZ2xlX29uLWEpIiB4bGluazpocmVmPSIjdG9nZ2xlX29uLWIiLz4gICAgPHVzZSBmaWxsPSIjRkZGIiB4bGluazpocmVmPSIjdG9nZ2xlX29uLWIiLz4gIDwvZz48L3N2Zz4=");
$custom-control-toggle-off: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNDAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCA0MCAyMCI+ICA8ZGVmcz4gICAgPGNpcmNsZSBpZD0idG9nZ2xlX29mZi1iIiBjeD0iMTAiIGN5PSIxMCIgcj0iOCIvPiAgICA8ZmlsdGVyIGlkPSJ0b2dnbGVfb2ZmLWEiIHdpZHRoPSIxMjUlIiBoZWlnaHQ9IjEyNSUiIHg9Ii0xMi41JSIgeT0iLTYuMiUiIGZpbHRlclVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCI+ICAgICAgPGZlT2Zmc2V0IGR5PSIxIiBpbj0iU291cmNlQWxwaGEiIHJlc3VsdD0ic2hhZG93T2Zmc2V0T3V0ZXIxIi8+ICAgICAgPGZlR2F1c3NpYW5CbHVyIGluPSJzaGFkb3dPZmZzZXRPdXRlcjEiIHJlc3VsdD0ic2hhZG93Qmx1ck91dGVyMSIgc3RkRGV2aWF0aW9uPSIuNSIvPiAgICAgIDxmZUNvbG9yTWF0cml4IGluPSJzaGFkb3dCbHVyT3V0ZXIxIiB2YWx1ZXM9IjAgMCAwIDAgMCAgIDAgMCAwIDAgMCAgIDAgMCAwIDAgMCAgMCAwIDAgMC4yIDAiLz4gICAgPC9maWx0ZXI+ICA8L2RlZnM+ICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICA8cmVjdCB3aWR0aD0iNDAiIGhlaWdodD0iMjAiIGZpbGw9IiNEQ0RDREMiIHJ4PSIxMCIvPiAgICA8dXNlIGZpbGw9IiMwMDAiIGZpbHRlcj0idXJsKCN0b2dnbGVfb2ZmLWEpIiB4bGluazpocmVmPSIjdG9nZ2xlX29mZi1iIi8+ICAgIDx1c2UgZmlsbD0iI0ZGRiIgeGxpbms6aHJlZj0iI3RvZ2dsZV9vZmYtYiIvPiAgPC9nPjwvc3ZnPg==");

$pointing-arrow: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'248'%20height%3D'150'%20viewBox%3D'0%200%20248%20152'%3E%3Cg%20fill%3D'none'%20fill-rule%3D'evenodd'%20stroke%3D'%23BFC5CD'%20stroke-linecap%3D'round'%3E%3Cpath%20d%3D'M0%2C149.872134%20C5.24581881%2C150.355329%2016.1899051%2C149.335346%2019.4320227%2C149.027394%20C120.864544%2C139.37796%20207.476141%2C95.1418162%20241%2C10'%2F%3E%3Cpolyline%20points%3D'233.812%208%20244.875%20.687%20247.937%2012.437'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E") !default;

$form-custom-dropdown-color: $gray-darker !default;
$form-icon-custom-dropdown: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3E%3Cpath fill='#{$form-custom-dropdown-color}' d='M5.00013742,6 C4.75263573,6 4.51846747,5.90025 4.36013305,5.73075 L0.193437926,1.23075 C-0.0140634911,1.0065 -0.0582304594,0.696 0.0784371407,0.432 C0.21593808,0.16875 0.509273417,0 0.833442298,0 L9.16683255,0 C9.49100143,0 9.78433677,0.16875 9.92183771,0.432 C10.0585053,0.696 10.013505,1.0065 9.80600358,1.23 L5.63930846,5.73 C5.48180738,5.90025 5.24763911,6 5.00013742,6 Z'/%3E%3C/svg%3E"), "#", "%23") !default;

$icon-close-color: $gray-dark;
$icon-close: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpolygon fill='#{$icon-close-color}' points='20.749,4.707 19.334,3.293 12.042,10.586 4.749,3.293 3.334,4.707 10.627,12 3.334,19.293 4.749,20.707 12.042,13.414 19.334,20.707 20.749,19.293 13.456,12'/%3E%3C/svg%3E"), "#", "%23") !default;

$arrow-right: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTBweCIKCSBoZWlnaHQ9IjEwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KICAgIDxwb2x5Z29uIGZpbGw9IiMwMENDNzciIGZpbGwtcnVsZT0iZXZlbm9kZCIgcG9pbnRzPSIxMC4yNDUgMTguMzY0IDEyLjk2IDIxLjA4IDIyIDEyLjA0IDEyLjk2IDMgMTAuMjQ1IDUuNzE2IDE0LjY0OCAxMC4xMTkgMiAxMC4xMTkgMiAxMy45NiAxNC42NDggMTMuOTYiLz4KPC9zdmc+");

$kiwi-sass-asset-helper: false !default;
$assets-path: '..' !default;
