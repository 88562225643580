.notifications-tr {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: $z-index-notification;
  transform: translateX(-50%);

  @include mq('small') {
    left: 0;
    right: 0;
    transform: none;
    padding: 10px;
  }
}

.notification {
  max-width: 860px;
  padding: 15px 20px;
  @include border-bottom-radius(7px);

  color: #fff;
  transition: opacity 0.5s;
  opacity: 0;

  .btn-close {
    opacity: 0.5;
  }

  & + & {
    margin-top: 10px;
    border-radius: 7px;
  }

  @include mq('small') {
    max-width: 450px;
    width: 100%;
    border-radius: 7px;
    margin: 0 auto;
    text-align: center;
    padding: 25px 30px;
  }
}

.notification-content {
  display: flex;
  align-items: flex-start;
  position: relative;

  @include mq('small') {
    align-items: center;
    flex-direction: column;

    .btn {
      position: absolute;
      top: -10px;
      right: -10px;
    }
  }
}

.notification-message {
  font-size: 16px;
  line-height: 1.2;
  padding: 0 15px 0 7px;
  flex: 1;

  @include mq('small') {
    padding: 0;
    margin-top: 10px;
  }
}

.notification-success {
  background-color: $brand-success;
}

.notification-info {
  background-color: $brand-info;
}

.notification-warning {
  background-color: $brand-warning;
}

.notification-error {
  background-color: $brand-danger;
}

.notification-visible {
  opacity: 0.9999;
}

.notification-hidden {
  opacity: 0;
}
