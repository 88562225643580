//==============================================================
//== Libraries and variables
//==============================================================

@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700&subset=latin-ext');
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

@import "variables";

@import "~bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/buttons";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/badges";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";
@import "~react-big-calendar/lib/css/react-big-calendar";

//==============================================================
//== Media Queries
//==============================================================

@mixin mq($q) {
  @if $q == 'phone' {
    @media (max-width: $screen-xs-max) { @content; }
  }

  @if $q == '!-phone' {
    @media (min-width: $screen-sm-min) { @content; }
  }

  @else if $q == '*-phablet' or $q == 'small' {
    @media (max-width: $screen-sm-max) { @content; }
  }

  @else if $q == 'phablet' {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { @content; }
  }

  @else if $q == '!-phablet' {
    @media (max-width: $screen-sm-max), (min-width: $screen-lg-min) { @content; }
  }

  @else if $q == 'phablet-*' {
    @media (min-width: $screen-sm-min) { @content; }
  }

  @else if $q == '*-tablet' {
    @media (max-width: $screen-md-max) { @content; }
  }

  @else if $q == 'tablet' {
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) { @content; }
  }

  @else if $q == '!-tablet' {
    @media (max-width: $screen-sm-max), (min-width: $screen-lg-min) { @content; }
  }

  @else if $q == 'tablet-*' or $q == 'large' {
    @media (min-width: $screen-md-min) { @content; }
  }

  @else if $q == 'desktop' {
    @media (min-width: $screen-lg-min) { @content; }
  }

  @else if $q == '!-desktop' or $q == 'mobile' {
    @media (max-width: $screen-md-max) { @content; }
  }

  @else if $q == 'desktop-sm' {
    @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) { @content; }
  }

  @else if $q == '!-desktop-sm' {
    @media (max-width: $screen-md-max), (min-width: $screen-xl-min) { @content; }
  }

  @else if $q == 'desktop-lg' {
    @media (min-width: $screen-xl-min) { @content; }
  }
}

@mixin retina() {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}

//==============================================================
//== Partials
//==============================================================

@import "generic";

@import "helpers/mixins";
@import "helpers/styleguide";
@import "helpers/animations";
@import "helpers/colors";
@import "helpers/grid";
@import "helpers/svg";
@import "helpers/placeholders";
@import "helpers/text";
@import "helpers/spacing";
@import "helpers/various";

@import "components/icons";
@import "components/forms";
@import "components/forms-custom";
@import "components/buttons";
@import "components/flags";
@import "components/nav";
@import "components/employee";
@import "components/modal";
@import "components/content-box";
@import "components/dropdown";
@import "components/popover";
@import "components/requests";
@import "components/tooltip";
@import "components/people-list";
@import "components/people-list-table";
@import "components/profile-summary";
@import "components/contact-info";
@import "components/settings-section";
@import "components/skeletons";
@import "components/notifications";
@import "components/overlay";
@import "components/secondary-nav";
@import "components/placeholder";
@import "components/cards-list";
@import "components/label";
@import "components/message-box";
@import "components/table";
@import "components/breadcrumb";
@import "components/list";
@import "components/expandable-nav";
@import "components/toggle-buttons";
@import "components/calendar-card";
@import "components/time-off-types";
@import "components/panel";
@import "components/alert";
@import "components/calendar";
@import "components/date";
@import "components/calendar-sm";
@import "components/select";
@import "components/progress";
@import "components/permissions-list";
@import "components/identifiers";
@import "components/date-switcher";

@import "templates/attendance";

@import "layout/table-grid";
@import "layout/flex";
@import "layout/layout";
@import "layout/phone";
@import "layout/navbar";
@import "layout/sidebar";
@import "layout/symmetric";

@import "templates/dashboard";
@import "templates/modal-form";
