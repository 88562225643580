.custom-control {
  position: relative;
  display: inline-block;
}

label.custom-control {
  font-size: 14px;
}

.custom-control-input {
  position: absolute;
  z-index: -1; // Put the input behind the label so it doesn't overlay text
  opacity: 0;
}

.custom-control-indicator {
  pointer-events: none;
  user-select: none;
  background-repeat: no-repeat;
  background-position: center center;
}

.checkbox {
  margin: 0 auto;
  display: inline-block;
}

.custom-checkbox {
  padding-left: 20px !important;
  display: block;
  cursor: pointer;

  .custom-control-indicator {
    position: absolute;
    top: 1px;
    left: 0;
    display: block;
    width: 2rem;
    height: 2rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    background-size: 60% 60%;
    background-color: #fff;
  }

  .custom-control-description {
    font-size: 16px;

    &:not(:empty) {
      padding-left: 10px;
    }
  }

  .custom-control-input:not(:checked) ~ .custom-control-indicator {
    border-color: $gray-light;
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-indicator {
    border-color: $gray;
    @extend %form-control-shadow;
  }

  .custom-control-input:checked ~ .custom-control-indicator,
  .custom-control-input[disabled]:checked ~ .custom-control-indicator {
    background-image: $custom-checkbox-checked-icon;
  }

  .custom-control-input:checked ~ .custom-control-indicator {
    color: $brand-primary;
    background-color: $brand-primary;
  }

  .custom-control-input:checked ~ .custom-control-description {
    color: $text-color;
  }

  .custom-control-input[disabled] ~ .custom-control-indicator {
    background-color: $gray-light;
  }
}

.checkbox--lg {
  .custom-control-indicator {
    width: 2rem;
    height: 2rem;
  }
}

.custom-control--toggle {
  margin: 0;
  line-height: 1;
  user-select: none;
  cursor: pointer;

  .custom-control-indicator {
    width: 40px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    background-image: $custom-control-toggle-off;
  }

  .custom-control-input:checked ~ .custom-control-indicator {
    background-image: $custom-control-toggle-on;
  }

  .custom-control-input[disabled] ~ .custom-control-indicator {
    opacity: .45;
  }
}

.custom-control--disabled {
  cursor: not-allowed;
}

.custom-control--tile {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;

  .custom-control-indicator {
    font-size: 12px;
    color: $gray-dark;
    border: $content-box-border;
    border-radius: $dashboard-border-radius;
    font-weight: normal;
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .custom-control-input:checked ~ .custom-control-indicator,
  &:hover .custom-control-indicator {
    border-color: $brand-secondary;
  }

  .custom-control-input:checked ~ .custom-control-indicator {
    border-width: 2px;
    color: $brand-secondary;
  }
}

.tiles {
  margin-bottom: 5px;
}

.upload-btn__wrapper {
  position: relative;
}

.upload-btn__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.form-control--sm {
  line-height: 30px;
  height: 30px;
}

.form-control-wrapper--icon {
  position: relative;

  .icon {
    position: absolute;
    left: 10px;
    top: 10px;
  }

  &.has-error {
    .icon {
      fill: $brand-danger;
    }
  }
}

.form-control__icon {
  fill: $gray;
}

.clear-btn {
  @extend %input-indicator;

  top: 0;
  right: 0;
  z-index: 10;

  .icon {
    fill: $gray;
  }

  &:hover {
    .icon {
      fill: $gray-dark;
    }
  }
}
