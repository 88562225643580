.calendar-card {
  text-align: center;
  color: $text-color;
}

.calendar-card--sm {
  width: 40px;
  height: 40px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.calendar-card__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.calendar-card--lg {
  width: 110px;
  height: 110px;
  display: inline-flex;
  flex-direction: column;
}

.calendar-card__day-month {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
}

.calendar-card__date {
  font-size: 8px;
  text-transform: uppercase;
  color: $gray-dark;
}

.calendar-card__day-week {
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: $gray;
}

.calendar-card--lg {
  .calendar-card__content {
    flex-grow: 1;
    position: relative;
  }

  .calendar-card__meta-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .calendar-card__day-month {
    font-size: 36px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    color: $text-color;
  }

  .calendar-card__day-week {
    display: block;
    padding: 2px 0;
  }

  .calendar-card__date {
    font-size: 10px;
    margin-bottom: 5px;
  }
}

a.calendar-card {
  @extend %card-transition;

  &:hover {
    @extend %card-shadow;
  }
}
