.content-box {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0;

  @include mq('phone') {
    border-width: 1px 0;
  }

  @include mq('phablet-*') {
    border-radius: 5px;
  }

  h1 {
    margin: 0;
    font-size: $content-box-header-font-size;
  }

  @extend .clearfix;
  position: relative;
}

ul.content-box {
  list-style: none;
}

.content-box--md {
  padding: 15px 30px;
}

.content-box--lg {
  padding: 40px;

  @include mq('small') {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.content-box--compact {
  padding: 0;
}

.content-box--bar {
  margin-bottom: 30px;
}

.content-box--shadow {
  box-shadow: $shadow-lg;
  border: 0;
  border-radius: $border-radius-lg;
}

.content-box + .content-box {
  margin-top: 30px;
}

.content-box__heading {
  &, h1, h2 {
    font-size: $content-box-header-font-size;
    margin: 0;
  }

  > .icon {
    vertical-align: -2px;
  }

  @include mq('tablet-*') {
    padding-left: 10px;
    border-bottom: $content-box-border;

    > .flex__row {
      height: $content-box-header-height;
    }
  }

  @include mq('*-phablet') {
    padding: 20px 20px 0;

    &--bordered {
      border-top: $content-box-border;
    }

    &--separating {
      padding-bottom: 20px;
      border-bottom: $content-box-border;
    }
  }
}

.content-box__heading--accent {
  padding: 15px 20px;
  color: #fff;
  border-top-left-radius: $default-border-radius;
  border-top-right-radius: $default-border-radius;
}

.content-box__heading + .table {
  border-top: none;
}

.content-box__close-btn {
  width: $content-box-header-height;
}

.content-box__main {
  padding: 25px 0;
}

.content-box__group {
  @include mq('large') {
    padding: 0 30px;
  }
}

.content-box__group--separated {
  padding-top: 25px;
  padding-bottom: 25px;

  @include mq('large') {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.content-box__description {
  padding: 20px 30px;

  @include mq('small') {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.content-box__description--bordered {
  border-bottom: 1px solid $default-border-color;
}

.head-description {
  color: #747474;

  .icon {
    margin-top: -2px;
  }

  p {
    margin-top: 0;
  }
}

.content-box__footer {
  padding: 18px 0;
  border-top: $content-box-border;
  background-color: $table-foot-bg;

  // this is hack
  &--bypass-parent-padding {
    margin: 40px -20px -40px;

    @include mq('desktop') {
      margin: 40px -80px -50px;
    }
  }
}

.custom-select--empty {
  color: #999;

  > option {
    color: $text-color;
  }
}

.content-box__extra {
  overflow: hidden;
  position: absolute;
  right: 20px;
  top: 0;
  transform: translateY(-100%);

  .george-error {
    position: relative;
    top: 8px;
  }
}
