.calendar-header {
  $header-height: 70px;

  height: $header-height;

  .heading__btn {
    height: $header-height;
    flex-grow: 0;
  }
}

.calendar-header--today {
  $arrow-size: 10px;
  $padding: $arrow-size * 2;

  position: relative;
  background-color: #fff;
  border-top: $default-border;
  padding: 0 $padding;
  font-size: 16px;
  height: 60px;
  margin: 0 0 $padding;

  &:after {
    content: '';
    top: 100%;
    left: $padding;
    display: block;
    position: absolute;
    pointer-events: none;
    border: $arrow-size solid transparent;
    border-top-color: #fff;
  }
}

.calendar-footer {
  height: 50px;
  padding: 0 20px;
  border: solid #ddd;
  border-width: 0 1px 1px;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  color: $gray-dark;

  display: flex;
  align-items: center;
  justify-content: center;
}

.rbc-calendar {
  display: block;
}

.rbc-month-view {
  border-radius: 5px 5px 0 0;
  background-color: #fff;
  display: block;
}

.rbc-month-header {
  height: 40px;
}

.rbc-header {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rbc-header,
.rbc-date-cell {
  color: $gray-dark;
}

.rbc-date-cell {
  padding: 15px 15px 2px;
  font-size: 16px;
  text-align: left;
}

.rbc-row-segment {
  padding: 0;
}

.event-wrapper {
  color: #fff;
}

.event-wrapper--pending {
  text-shadow: none;
  color: #333;
}

.rbc-month-row {
  height: 170px;
}

.rbc-event {
  font-size: 13px;
  background-color: transparent;
  color: inherit;
  padding: 4px 0 0;

  &.rbc-selected {
    background-color: transparent;
  }
}

.rbc-event-inner {
  margin: 0 10px;
  border-radius: 100px;
  padding: 1px 10px;

  :not(.rbc-selected) > & {
    box-shadow: none !important;
  }
}

:not(.rbc-overlay) > span > .event-wrapper > .rbc-event-continues-after .rbc-event-inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 0;
}

:not(.rbc-overlay) > span > .event-wrapper > .rbc-event-continues-prior .rbc-event-inner {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

.rbc-overlay {
  padding: 0 0 10px;
  border-radius: $default-border-radius;
}

.rbc-overlay-header {
  margin: 0 0 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.rbc-day-bg + .rbc-day-bg {
  border-left-color: #d7d7d7;
}

.rbc-today {
  $rbc-today-size: 7px;
  position: relative;
  background-color: transparent;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    @include triangle($brand-primary, $rbc-today-size, left);
    transform: translate(-$rbc-today-size, -$rbc-today-size) rotate(45deg);
    content: "";
  }

  &.rbc-off-range-bg {
    &::before {
      @include triangle($gray, $rbc-today-size, left);
    }
  }
}

.rbc-now {
  font-weight: bold;
  color: $gray-darker;
}

.rbc-off-range {
  color: $gray;
}

.rbc-off-range-bg {
  background-color: #ececec;
}

.rbc-date-cell {
  > a {
    pointer-events: none;
  }
}

.rbc-show-more {
  margin: 5px 10px 0;
  font-size: 12px;
  font-weight: normal;
  background-color: transparent;
  color: $gray-dark;

  &:hover,
  &:focus {
    color: $gray-dark;
  }
}

.rbc-tooltip {
  width: 320px;
}

.rbc-tooltip--wide {
  width: 400px;
}

.rbc-identifier {
  padding: 15px 0 15px 0;
}

.rbc-person-identifier {
  padding-left: 20px;
}

.rbc-module {
  padding: 10px 20px;
}

.rbc-tot-identifier {
  background-color: $color-app-bg;

  .item-list__primary {
    font-size: 14px;
  }

  .icon--separated {
    margin: 0 10px !important;
  }
}

.rbc-event-duration {
  padding: 10px 20px;
}

.calendar-legend {
  @extend %list;
  display: flex;
}

.calendar-legend__item + .calendar-legend__item {
  margin-left: 40px;
}

.calendar-legend__example {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 50%;
  display: inline-block;
}

.my-calendar-toggle {
  display: inline-flex;
  align-items: center;
}

.link-with-icon {
  color: #999;

  > svg {
    fill: $gray;
  }

  &:hover {
    text-decoration: none;
    color: $gray-dark;

    > svg {
      fill: #999;
    }
  }
}
