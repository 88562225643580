.message-box {
  max-width: 515px;

  h1 {
    margin-top: 0;
  }
}

.message-box__icon-wrapper {
  margin-bottom: 40px;
}

.message-box__description {
  margin-top: 25px;
  font-size: 18px;
  color: $gray-dark;
}
