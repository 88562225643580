%ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

%table-row-hover-line {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: $hover-border-width;
}

%input-indicator {
  position: absolute;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  margin: 0 !important; // must overwrite lib values
  top: -1px;
  background-color: transparent;
}
