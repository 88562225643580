.tooltip {
  font-size: $font-size-base;
  white-space: nowrap;
}

.tooltip-inner {
  padding: 7px 17px;
  @include box-shadow(0 2px 7px 0 rgba(#000, 0.15));
}

@include mq('!-desktop') {
  .tooltip {
    display: none !important;
  }
}
