.identifier-link {
  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:hover {
    b {
      text-decoration: underline;
    }
  }
}
