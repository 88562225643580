@import '../vendor/_datepicker';

.date-picker--down .SingleDatePicker_picker {
  margin-top: -1px;
}

.date-picker--up .SingleDatePicker_picker {
  margin-bottom: -1px;
}

.SingleDatePicker_picker {
  z-index: $z-index-popover;

  @include mq('small') {
    margin-top: 0;
    top: 0 !important;
    height: 100vh;
  }
}

.DayPicker {
  border-radius: $default-border-radius;
  box-shadow: none;

  @include mq('large') {
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.15);
  }
}

.CalendarMonth_caption {
  color: $gray-darker;
}

.DayPicker_weekHeader {
  text-transform: uppercase;
  font-weight: bold;
  color: $gray-dark;
}

.DayPicker_weekHeader_li {
  font-size: 13px;
}

.CalendarMonth_table tr:first-of-type .CalendarDay__outside {
  border-width: 1px 0 0 1px;
}

.DateInput_input {
  @extend .form-control;
  border-color: $default-border-color;
}

.DateInput_input__focused {
  border: $default-border;
}

.DayPickerNavigation_button {
  border: $default-border;
  background-color: transparent;

  &:hover .icon {
    fill: $gray-dark !important;
  }
}

.DayPickerNavigation_button__horizontal {
  padding: 5px;
}

.DateInput_fang {
  // prevent datepicker shadow being painted on top of the fang
  z-index: $z-index-popover + 1;
}

.DateInput_fangStroke {
  display: none;
}

.input-group .DateInput_input {
  z-index: initial;
}

.SingleDatePickerInput__showClearDate {
  padding-right: 0;
}

.SingleDatePickerInput_clearDate {
  @extend %input-indicator;

  .SingleDatePickerInput_clearDate_svg {
    vertical-align: 0;
    fill: $gray;
  }

  &:hover,
  &:focus {
    background-color: transparent;

    .SingleDatePickerInput_clearDate_svg {
      fill: $gray-dark;
    }
  }
}

.DayPicker_portal__horizontal {
  position: absolute;
  z-index: $z-index-popover;

  @include mq('small') {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
  }
}

// Styles added specifically for DateRangeField type day picker, if anything breaks - remove
.DayPicker_portal__horizontal_3 {
  @include mq('large') {
    margin: 0 !important;
    top: 58px;
    left: 0;

    &:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M0,10 20,10 10,0z'/%3E%3Cpath d='M0,10 10,0 20,10'/%3E%3C/svg%3E%0A");
      position: absolute;
      top: -10px;
      left: 22px;
      width: 20px;
      height: 10px;
    }
  }
}

.SingleDatePicker_closeButton{
  z-index: $z-index-modal;
}

// CALENDAR DAY APPEARANCE OVERRIDES
.CalendarDay {
  color: $gray-dark;
  border-color: $default-border-color;

  &:hover {
    &:not(.CalendarDay__outside) {
      border: $default-border;
    }

    &:not(.CalendarDay__blocked_calendar):not(.CalendarDay__selected) {
      color: $gray-darker;
      background: rgba($brand-secondary, 0.2);
    }
  }

  &:focus {
    outline: $brand-secondary solid 2px;
  }
}

.CalendarDay__hovered_span,
.CalendarDay__selected_span {
  &,
  &:active,
  &:hover {
    background: rgba($brand-secondary, 0.1);
    color: $gray-dark;
  }
}

.CalendarDay__selected {
  &,
  &:hover,
  &:active {
  color: #fff;
    background: $brand-secondary;
  }
}

.CalendarDay__today {
  $rbc-today-size: 5px;
  font-weight: bold;
  position: relative;
  z-index: 0;
  color: $brand-secondary;

  &.CalendarDay__selected {
    color: #fff;
  }

  &:before {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 32px;
    height: 32px;
    line-height: 10px;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    border: 2px solid $brand-secondary;
    content: "";
    z-index: -1;
  }
}

.CalendarDay__blocked_calendar {
  &,
  &:hover,
  &:active {
    background-color: #e5e5e5;
    color: $gray !important;
  }
}

.CalendarDay__outside {
  &,
  &:hover,
  &:active {
    border: $default-border;
    color: $gray-light;
    border-color: #ededed;
  }
}
