.flag {
  height: 33px;
  line-height: 32px;
  margin-right: 13px;
  padding: 0 10px 0 15px;
  position: relative;
  display: inline-block;
  border-width: 1px 0 1px 1px;
  @include border-left-radius(4px);

  &__arrow {
    right: 2px;
    top: -1px;
    transform: translate(100%, 0);
    position: absolute;
    z-index: 100;
  }

  &--success {
    border-color: $brand-primary;
    border-style: solid;

    path {
      fill: none;
      stroke: $brand-primary;
      stroke-width: 1px;
    }
  }

  &--danger {
    border-color: $brand-danger;
    border-style: solid;

    path {
      fill: none;
      stroke: $brand-danger;
      stroke-width: 1px;
    }
  }

  &--pending {
    border-color: $gray;
    border-style: dashed;

    path {
      fill: none;
      stroke: $gray;
      stroke-width: 1px;
      stroke-dasharray: 4 2;
    }
  }
}
