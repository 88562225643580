.tg__row {
  width: 100%;
  display: table;
}

.tg__col {
  width: 33.33333%;
  display: table-cell;
  vertical-align: top;
  position: relative;
}

.tg__col--narrow {
  width: 1%;
  text-align: center;
}

.tg__col--full {
  width: 100%;
}

.tg__col--half {
  width: 50%;
}

.tg__col--quarter {
  width: 25%;
}

.tg__col--bottom {
  position: relative;
  vertical-align: bottom;
}

.tg__col--right {
  text-align: right;
}

.tg__col--middle {
  vertical-align: middle;
}

.tg__row--middle {
  > .tg__col {
    vertical-align: middle;
  }
}
