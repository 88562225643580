.employee {
  font-size: 16px;
  font-weight: bold;

  .profile-pic {
    margin-right: 15px;
  }

  &--sm {
    font-size: 14px;
  }

  &--lg {
    font-size: 30px;
  }

  &.l-horizontal {

  }

  &.l-vertical {
    text-align: center;

    .img-circle {
      margin: 0 auto;
      display: block;
    }
  }

  &__position {
    font-size: 10px;
    color: $gray-dark;
  }

  &__email {
    margin-top: 5px;
    font-size: 13px;

    .icon {
      margin-right: 4px;
      vertical-align: middle;
    }
  }

  &__data {
    display: inline-block;
    vertical-align: middle;
  }
}

.employee.l-vertical {
  .employee__position {
    margin: 5px 0 2px;
    font-size: 10px;
    text-transform: uppercase;
  }
}

.employee--sm {
  .employee__name,
  .employee__position {
    display: inline;
  }
}

.employee--lg {
  .employee__position {
    font-size: 12px;
  }
}
.img-circle {
  box-sizing: content-box;

  &--dimmed {
    filter: grayscale(100%);
    opacity: .5;
  }
}

.employee--inverted {
  color: #fff;

  .employee__position {
    color: $brand-primary;
  }
}

.employee__wrapper {
  overflow: hidden;
  max-width: calc(100% - 100px);
  line-height: 1;

  > .employee__label {
    line-height: 1.2;
    margin-top: -1px;
  }
}


.profile-badge {
  padding: 10px 20px;

  @include mq('small') {
    .profile-pic {
      margin-right: 10px;
    }
  }
}

.profile-badge__name {
  font-size: 18px;

  @include mq('small') {
    margin: 0;
  }
}
