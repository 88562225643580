.icon {
  vertical-align: middle;
  flex-shrink: 0;

  &-xxs {
    width: 12px;
    height: 12px;
  }

  &-xs {
    width: 16px;
    height: 16px;
  }

  &-sm {
    width: 20px;
    height: 20px;
  }

  &-md {
    width: 24px;
    height: 24px;
  }

  &-lg {
    width: 32px;
    height: 32px;
  }

  &-xl {
    width: 40px;
    height: 40px;
  }

  &-xxl {
    width: 48px;
    height: 48px;
  }

  &-primary {
    fill: $brand-primary;
  }

  &-secondary {
    fill: $brand-secondary;
  }

  &-gray {
    fill: $gray;
  }

  &-gray-dark {
    fill: $gray-dark;
  }

  &-danger {
    fill: $brand-danger;
  }

  &-success {
    fill: $brand-success;
  }

  &-warning {
    fill: $brand-warning;
  }
}

.icon--separated {
  margin: 0 20px !important;

  @include mq('small') {
    margin: 0 10px !important;
  }
}

.icon--separated-left {
  margin-left: 20px !important;

  @include mq('small') {
    margin-left: 10px !important;
  }
}

.icon--separated-right {
  margin-right: 20px !important;

  @include mq('small') {
    margin-right: 10px !important;
  }
}

.icon--hover-toggle {
  .icon:last-of-type {
    display: none;
  }

  &:hover {
    .icon:first-of-type {
      display: none;
    }

    .icon:last-of-type {
      display: inline-block;
    }
  }
}
