%form-control-shadow {
  @include box-shadow(inset 0 2px 4px 1px rgba(#e8e8e8, 0.7));
}

%label {
  font-size: 16px;
  font-weight: normal;
  color: $gray-dark;
}

.form-control,
.form-group__validation {
  background-repeat: no-repeat;
  background-position: center right .625rem;
  background-size: 1.25rem 1.25rem;
}

.form-control {
  max-width: 100%;
  font-size: 16px;
  border-color: $default-border-color;
  color: $text-color;
  padding: 9px 18px;
  border-radius: 5px;
  @include form-control-focus($gray);

  &.text-center {
    padding-left: 9px;
    padding-right: 9px;
  }

  &[disabled] {
    background-color: #fbfbfb;
    color: $gray-lighter;
    font-style: normal;
  }

  &[readonly] {
    background-color: #fbfbfb;
    color: $text-color;
    box-shadow: none;

    &:focus {
      border-color: $default-border-color;
    }
  }
}

textarea.form-control[readonly] {
  resize: none;
}

.form-control__counter {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 10px;
  line-height: 10px;
  color: $gray-dark;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.form-control {
  line-height: $input-height-base;
}

input.input-sm {
  font-size: 14px;
  line-height: $input-sm-height-base;
}

.input-group-addon {
  padding: 6px 7px;
  border-color: $gray-light;
  background-color: #fff;
}

.form-control--shadow {
  &:focus {
    @extend %form-control-shadow;
  }
}

.form-control--no-errors {
  border-color: $default-border-color !important;
}

select.form-control {
  line-height: 1.2;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: $form-icon-dropdown;
}

select.form-control::-ms-expand {
  display: none;
}

.select-pure,
.select-pure:focus,
.select-pure:active {
  border: none;
  background-color: transparent;
  box-shadow: none;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

.has-error {
  .form-control:focus {
    @include box-shadow(0 0 5px 0 rgba($brand-danger, 0.7));
  }

  &.symmetric-layout__group--vertical {
    .control-label {
      margin-top: 5px;
      font-size: 12px;
    }
  }

  .input-group-addon {
    background-color: transparent;
  }

  .rs__control,
  .custom-control-indicator {
    border-color: $brand-danger !important;
  }
}

.is-valid {
  .form-control {
    border-color: $brand-primary;
  }

  .form-control:focus {
    @include box-shadow(0 0 5px 0 rgba($brand-success, 0.7));
  }
}

.validation-msg {
  margin: 0 0 3px 10px;
  display: none;

  &--error {
    color: $brand-danger;
  }

  &--warning {
    color: $brand-warning;
  }
}

.user-form {
  h1 {
    margin-top: 0;
  }

  label {
    font-size: 14px;
    font-weight: normal;
    color: #747474;
  }

  .form-control {
    color: $text-color;
    @extend .form-control--shadow;
  }
}

.user-form__wrapper {
  margin: 0 auto;
  max-width: 400px;

  &--info {
    max-width: 515px;
  }

  > h1 {
    margin: 0 0 20px;
  }
}

.user-form__heading {
  text-align: center;
  margin-bottom: 20px;
}

.user-form__lead {
  font-size: 18px;
  color: $gray-dark;
}

.user-form__wrapper--info {
  .user-form__lead {
    margin-top: 25px;
  }
}

.user-form__icon-wrapper {
  margin-bottom: 40px;
}

.user-form__domain {
  font-size: 18px;
  font-weight: bold;
  margin: 2px 0 0 10px;
  color: $gray-dark;
}

.user-form__submit {
  margin-top: 30px;
}

.user-form__footer {
  margin-top: 25px;
}

.form__footer {
  padding: 0 20px 10px 20px;
}

.input-extensions {
  padding: 7px 8px 6px 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;

  display: flex;
  align-items: center;

  &__wrapper {
    position: relative;
  }

  &__item {
    display: inline-block;

    & + & {
      margin-left: 5px;
    }

    &--button {
      margin-top: 3px;
    }
  }
}

.password-strength {
  &__indicator {
    width: 6px;
    height: 6px;
    display: block;
    margin-top: 3px;
    background-color: $gray-light;

    &:first-child {
      margin-top: 0;
    }

    &--weak {
      background-color: #ff3f20;
    }

    &--medium {
      background-color: #ff8a00;
    }

    &--good {
      background-color: #41beee;
    }

    &--strong {
      background-color: #42b357;
    }
  }
}

.show-password {
  font-size: 16px;
}

.user-form__note {
  &,
  a {
    color: #8b8e94;
  }

  a {
    text-decoration: underline;
  }

  &--primary {
    font-size: 16px;
    color: $gray-dark;

    a {
      color: $text-color;
      text-decoration: none;
    }
  }

  &--light {
    a {
      color: $gray-dark;
    }
  }
}

.input-password {
  padding-right: 50px;
}

.form-group__validation {
  position: relative;
  margin-bottom: 15px;
}

.form-group--horizontal {
  .form-group {
    margin-bottom: 0;
  }
}

.form-group--textarea {
  position: relative;

  textarea {
    min-width: 100%;
  }
}

label:not(.btn) {
  @extend %label;
}

.form-group__link {
  font-size: 16px;
  cursor: pointer;
}

.form-group__field {
  &--no-access {
    font-size: 16px;
  }
}

.form-group__error {
  position: absolute;
  font-size: 12px;
  top: -20px;
}

.form-group__description {
  font-size: 12px;
}

.form-group__description--separated {
  margin-top: 5px;
}

@include mq('tablet-*') {
  .form-group--horizontal {
    .form-group__field--no-access {
      padding: 0 20px;
    }

    .form-group__validation {
      display: inline-block;
      vertical-align: middle;
    }

    .control-label {
      height: 100%;
      display: flex;
      margin-bottom: 0;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
      color: $gray-dark;
    }

    .form-group--textarea {
      .control-label {
        height: 40px;
      }
    }

    .offset-label {
      margin-left: 220px;
    }

    .symmetric-layout__col--middle {
      position: relative;
    }
  }
}

.valid-indicator {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #fff;
  border-radius: 50%;
  display: none;
}

.is-valid .valid-indicator {
  display: block;
}

.sign-up__submit-btn {
  &,
  &.disabled {
    cursor: pointer;
  }
}

.visibility-info {
  font-size: 13px;
  margin-top: 1rem;
  font-weight: normal;
  color: $gray-dark;
  display: flex;
  align-items: center;
}

.visibility-info__icon {
  .icon {
    vertical-align: -2px;

    @include mq('small') {
      margin-right: 10px;
    }
  }

  @include mq('tablet-*') {
    margin-left: 13px;
    display: inline-block;
  }
}

.custom-holiday-form {
  border-top: $default-border;

  .table__cell {
    vertical-align: bottom;
    padding-bottom: 30px;
    padding-top: 15px;
  }

  .form-group {
    margin-bottom: 0;
  }

  > td > .icon {
    height: 40px;
  }
}

.form-control-suffix {
  font-size: 16px;
  margin-left: 10px;
  color: $gray-dark;
}

.form-control-suffix--sm {
  font-size: 12px;
  line-height: 1.2;
}

.form-control--unified {
  &[readonly],
  &[disabled] {
    background-color: #fff;
  }
}

.label-block {
  display: block;
  margin-bottom: 0;

  input {
    margin-top: 3px;
  }
}
